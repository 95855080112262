import { useEffect, useState } from "react";
import PieChart from "../../charts/PieChart";

export default function UsiaDalamTahun({ data }) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 0.8)',
            'rgba(4,135,55, 0.6)',
            'rgba(4,135,55, 0.3)',
            'rgba(4,135,55, 0.2)',
            'rgba(4,135,55, 0.1)',
          ],
          borderColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    data.map(item => {
      if (mapData[item.usia] !== undefined) {
        mapData[item.usia] += 1
      } else {
        mapData[item.usia] = 1
      }
      return item
    })
    rawData.labels = Object.keys(mapData)
    rawData.datasets[0].data = []
    rawData.labels.map(item => {
      rawData.datasets[0].data.push(((mapData[item])*100).toFixed(1))
      return item
    })


    // Initialize counters for each age group
    let ageGroups = {
      '18-34': ((data.filter(item => item["usia"] >= 18 && item["usia"] <= 34).length / data.length)*100).toFixed(1),
      '35-54': ((data.filter(item => item["usia"] >= 35 && item["usia"] <= 54).length/ data.length)*100).toFixed(1),
      '> 55': ((data.filter(item => item["usia"] >= 55 ).length/ data.length)*100).toFixed(1)
    };

    // Count the number of people in each age group
    // const datas = rawData.datasets[0].data;
    // Object.entries(datas).forEach(([age, count]) => {
    //   if (age >= 18 && age <= 34) {
    //     ageGroups['18-34'] += parseFloat(count);
    //   } else if (age >= 35 && age <= 54) {
    //     ageGroups['35-54'] += parseFloat(count);
    //   } else {
    //     ageGroups['> 55'] += parseFloat(count);
    //   }
    // });
    console.log("ageGroups",ageGroups)
    rawData.labels = Object.keys(ageGroups)
    rawData.datasets[0].data = Object.values(ageGroups)
    setGraphData(rawData)
  }, [data]);

  return <div style={{ width: '250px', height: '250px', margin:"10px auto" }}><PieChart data={graphData} /></div>
}