import { useEffect, useState } from "react";
import BarChart from "../../charts/BarChart";
const labelFields = [
  'Sangat sering',
  'Lebih sering',
  'Hampir sama',
  'Jarang',
  'Sangat jarang',
  'Lupa/ tidak jawab'
]
export default function FrekuensiBar({ data }) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 0.8)',
            'rgba(4,135,55, 0.6)',
            'rgba(4,135,55, 0.3)',
            'rgba(4,135,55, 0.2)',
            'rgba(4,135,55, 0.1)',
          ],
          borderColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    labelFields.map(field => {
      mapData[field] = 0
      return field
    })
    data.map(item => {
      if(item["pengaruh_frekuensi_media"] === "1"){
        mapData["Sangat sering"] += 1
      }
      if(item["pengaruh_frekuensi_media"] === "2"){
        mapData["Lebih sering"] += 1
      }
      if(item["pengaruh_frekuensi_media"] === "3"){
        mapData["Hampir sama"] += 1
      }
      if(item["pengaruh_frekuensi_media"] === "4"){
        mapData["Jarang"] += 1
      }
      if(item["pengaruh_frekuensi_media"] === "5"){
        mapData["Sangat jarang"] += 1
      }
      if(item["pengaruh_frekuensi_media"] === "6"){
        mapData["Lupa/ tidak jawab"] += 1
      }
      return item
    })
    rawData.labels = Object.keys(mapData)
    rawData.datasets[0].data = []
    rawData.labels.map(item => {
      rawData.datasets[0].data.push(((mapData[item]/data.length)*100).toFixed(1))
      return item
    })


    const labelsWithLineBreaks = rawData.labels.map(label => label.split(' '));

    const modifiedData = {
      ...rawData,
      labels: labelsWithLineBreaks,
    };
    setGraphData(modifiedData)
  }, [data]);

  return (
    <BarChart
      height={250}
      isXaxis={false}
      data={graphData}
    />
  )
}