import { useEffect, useState } from "react"
import BarChart from "../../charts/BarChart";

const labelBentukBantuan = {
  'Penyuluh pemerintah': 'sumber_informasi_penyuluh/petugas_pemerintah',
  'Pemuka agama': "sumber_informasi_pemuka_agama",
  'Koperasi': 'sumber_informasi_koperasi_pertanian/sejenisnya',
  'LSM': "sumber_informasi_LSM",
  'TV': "sumber_informasi_TV",
  'Radio': "sumber_informasi_radio",
  'Internet': "sumber_informasi_internet",
  'Kawan/tetangga/keluarga': 'sumber_informasi_kawan/tetangga/keluarga',
  'Lainnya': 'sumber_informasi_lainnya',
}

const labelsWithLineBreaks = Object.keys(labelBentukBantuan).map(label => label.split(' '));
export default function SumberInformasi({data}) {
  const [bentukBantuan, setBentukBantuan] = useState({})
  useEffect(() => {
    const fields = Object.values(labelBentukBantuan)
    const fieldsObj = {}
    fields.map(field => {
      fieldsObj[field] = 0
      return field
    })
    data.map(item => {
      fields.map(field => {
        if (item[field] === true) {
          fieldsObj[field] += item[field]
        }
        return field
      })
      return item
    })
    const rawData = []
    fields.map(field => {
      rawData.push(((fieldsObj[field]/data.length) * 100).toFixed(1))
      return field
    })


    setBentukBantuan(rawData)
  }, [data]);

  return (
    <>
      <BarChart
        isHorizontal={true}
        height={450}
        isXaxis={false}
        width={400}
        data={{
          labels: labelsWithLineBreaks,
          datasets: [
            {
              label: '',
              data: bentukBantuan,
              backgroundColor: [
                '#048737',
                '#048737',
                '#048737',
                '#048737',
                '#048737',
                '#048737',
              ],
            },
          ]
        }}
      />
    </>
  )
}
