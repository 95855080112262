import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const options = {
  // maintainAspectRatio: false,
  // responsive: false,
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          return context.formattedValue + '%'
        }
      }
    },
    datalabels: {
      align: 'end', // Position the labels at the end of the bars
      anchor: 'end', // Anchor the labels at the end of the bars
      formatter: (value) => {
        return `${value}%`; // Format the labels as percentages
      },
      color: '#222',
      font: {
        size: 10, // Set the font size here
      }
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
    datalabels :{
      display: false
    }
  },
};


export default function LineChart({ data, ...props }) {
  return <Line options={options} data={data} { ...props } />;
}
