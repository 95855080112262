import { useEffect, useState } from "react";
import PieChart from "../../charts/PieChart";

export default function PercentPenurunanHarga({ data }) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [],
          backgroundColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 0.8)',
            'rgba(4,135,55, 0.6)',
            'rgba(4,135,55, 0.3)',
            'rgba(4,135,55, 0.2)',
            'rgba(4,135,55, 0.1)',
          ],
          borderColor: [
            'rgba(4,135,55, 0.5)',
            'rgba(4,135,55, 0.5)',
            'rgba(4,135,55, 0.5)',
            'rgba(4,135,55, 0.5)',
            'rgba(4,135,55, 0.5)',
            'rgba(4,135,55, 0.5)',
          ],
          borderWidth: 1,
          fill: true
        },
      ],
    };

    let ageGroups = {
      '<30%': ((data.filter(item => item.penurunan_harga_jual < 30).length / data.filter(item => Number.isInteger(item["penurunan_harga_jual"])).length) * 100).toFixed(1),
      '30-50%':  ((data.filter(item => item.penurunan_harga_jual >= 30 && item.penurunan_harga_jual <= 50).length/ data.filter(item => Number.isInteger(item["penurunan_harga_jual"])).length) * 100).toFixed(1),
      '>50%': (( data.filter(item => item.penurunan_harga_jual > 50).length/ data.filter(item => Number.isInteger(item["penurunan_harga_jual"])).length) * 100).toFixed(1),
    };
    const fMapData = {}
    console.log("fMapData",ageGroups)
    rawData.labels = Object.keys(fMapData)
    // Count the number of people in each age group
    rawData.labels = Object.keys(ageGroups)
    rawData.datasets[0].data = Object.values(ageGroups)
    setGraphData(rawData)
  }, [data]);

  return <PieChart height={100} data={graphData} />
}