import { useEffect, useState } from "react";
import BarChart from "../../charts/BarChart";
const labelFields = [
  'Upah',
  'Usaha',
  'Keluarga',
  'Bantuan',
  'Lainnya'
]
export default function SumberPendapatan({ data }) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 0.8)',
            'rgba(4,135,55, 0.6)',
            'rgba(4,135,55, 0.3)',
            'rgba(4,135,55, 0.2)',
            'rgba(4,135,55, 0.1)',
          ],
          borderColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    labelFields.map(field => {
      mapData[field] = 0
      return field
    })
    data.map(item => {
      if(item["pendapatan_upah"] === 1){
        if (mapData["Upah"] !== undefined) {
          mapData["Upah"] += 1
        } else {
          mapData["Upah"] = 1
        }
      }
      if(item["pendapatan_usaha"] === 1){
        if (mapData["Usaha"] !== undefined) {
          mapData["Usaha"] += 1
        } else {
          mapData["Usaha"] = 1
        }
      }
      if(item["pendapatan_keluarga"] === 1){
        if (mapData["Keluarga"] !== undefined) {
          mapData["Keluarga"] += 1
        } else {
          mapData["Keluarga"] = 1
        }
      }
      if(item["pendapatan_bantuan"] === 1){
        if (mapData["Bantuan"] !== undefined) {
          mapData["Bantuan"] += 1
        } else {
          mapData["Bantuan"] = 1
        }
      }
      if(item["pendapatan_lainnya"] === 1){
        if (mapData["Lainnya"] !== undefined) {
          mapData["Lainnya"] += 1
        } else {
          mapData["Lainnya"] = 1
        }
      }
      return item
    })
    rawData.labels = Object.keys(mapData)
    rawData.datasets[0].data = []
    rawData.labels.map(item => {
      rawData.datasets[0].data.push(((mapData[item]/data.length)*100).toFixed(1))
      return item
    })
    setGraphData(rawData)
  }, [data]);

  return (
    <BarChart
    height={262}
    isXaxis={false}
    isHorizontal={true}
      data={graphData}
    />
  )
}