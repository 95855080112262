import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export const options = {
  maintainAspectRatio: true,
  responsive: true,
  plugins: {
    legend: {
      display: true,
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          console.log(context)
          return  context.dataset.label +" : "+ context.formattedValue + '%'
        }
      }
    },
    datalabels: {
      align: 'center', // Position the labels at the end of the bars
      anchor: 'center', // Anchor the labels at the end of the bars
      formatter: (value) => {
        return `${value}%`; // Format the labels as percentages
      },
      color: '#222',
      font: {
        size: 10, // Set the font size here
      }
    },
  },
  // barThickness: 8,
  // barPercentage: 0.4,
  scales: {
    x: {
      stacked: true,
      max: 100,
      grid: {
        display: false
      },
      ticks: {
        stepSize: 50,
        font: {
          size: 14
        }
      }
    },
    y: {
      stacked: true,
      max: 100,
      grid: {
        display: false
      },
      ticks: {
        font: {
          size: 14
        }
      }
    }
  },
  layout: {
    margin: 0,
    padding: {
      top: 30,
      right: 30,
    }
  }
};

export default function BarStackChart({ data, isHorizontal = false, isXaxis = true,...props }) {
  // if (isHorizontal) {
  //   options.indexAxis = 'y'
  // }
  let scalesX = {
    x: {
      stacked: true,
      max: 100,
      grid: {
        display: false
      },
      ticks: {
        display: false,
        stepSize: 50,
        font: {
          size: 13
        }
      }
    },
    y: {
      stacked: true,
      max: 100,
      grid: {
        display: false
      },
      ticks: {
        display: true,
        font: {
          size: 13
        }
      }
    }
  }

  let scalesY = {
    x: {
      stacked: true,
      max: 100,
      grid: {
        display: false
      },
      ticks: {
        stepSize: 50,
        font: {
          size: 13
        }
      }
    },
    y: {
      stacked: true,
      max: 100,
      grid: {
        display: false
      },
      ticks: {
        display: false,
        font: {
          size: 13
        }
      }
    }
  }
  const theOptions = { ...options }
  theOptions.plugins = { ...options.plugins }
  if (props.title) {
    theOptions.plugins.title = {
      display: true,
      text: props.title
    }
  }
  return (
    <Bar
      options={{ ...theOptions, indexAxis: isHorizontal ? 'y' : 'x', scales :isXaxis ? theOptions.scales: !isXaxis && !isHorizontal ? scalesY: scalesX }}
      data={data}
      {...props}
    />
  )
}