import { useEffect, useState } from "react";
import BarChart from "../../charts/BarChart";

const labelFields = {
  'Anggota keluarga lain': 'pelaku_produksi_anggota_keluarga_lain',
  'Orang lain/rekan kerja': 'pelaku_produksi_orang_lain/rekan_kerja',
  'Pribadi dan istri/keluarga': 'pelaku_produksi_saya_dan_istri/anggota_keluarga_lain',
  'Sendiri & rekan kerja': 'pelaku_produksi_saya_dan_rekan_kerja',
  'Sendiri': 'pelaku_produksi_saya_sendiri',
  'Suami/istri sendiri': 'pelaku_produksi_suami/istri_saya',
  'Lainnya': 'pelaku_produksi_lainnya',
}

export default function PelakuProduksi({ data }) {
  const [graphData, setGraphData] = useState({
    labels: Object.keys(labelFields),
    datasets: []
  })

  useEffect(() => {
    const rawData = {
      labels: Object.keys(labelFields),
      datasets: [
        {
          label: '',
          data: [],
          barThickness: 8,
          barPercentage: 0.4,
          backgroundColor: [
            '#048737',
            '#048737',
            '#048737',
            '#048737',
            '#048737',
            '#048737',
          ],
        },
      ]
    }
    const fields = Object.values(labelFields)
    const fieldsObj = {}
    fields.map(field => {
      fieldsObj[field] = 0
      return field
    })
    data.map(item => {
      fields.map(field => {
        if (item[field] !== undefined) {
          fieldsObj[field] += item[field]
        }
        return field
      })
      return item
    })
    fields.map(field => {
      rawData.datasets[0].data.push(((fieldsObj[field]/data.length) * 100).toFixed(1))
      return field
    })
    const sortedData = {
      ...rawData,
      labels: [...rawData.labels].sort((a, b) => rawData.datasets[0].data[rawData.labels.indexOf(a)] - rawData.datasets[0].data[rawData.labels.indexOf(b)]),
      datasets: rawData.datasets.map(dataset => ({
        ...dataset,
        data: [...dataset.data].sort((a, b) => a - b), // Sort data in descending order
      })),
    };
    setGraphData(sortedData)
  }, [data]);

  return (
    <div style={{  height: '400px', margin:"10px auto" }}>
    <BarChart
      isHorizontal={true}
      height={300}
      isXaxis={false} 
      data={graphData}
    /></div>
  )
}