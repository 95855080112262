import { useEffect, useState } from "react";
import BarChart from "../../charts/BarChart";

export default function AsalKabupaten({ data }) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 0.8)',
            'rgba(4,135,55, 0.6)',
            'rgba(4,135,55, 0.3)',
            'rgba(4,135,55, 0.2)',
            'rgba(4,135,55, 0.1)',
          ],
          borderColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    data.map(item => {
      if (mapData[item.nama_kabupaten] !== undefined) {
        mapData[item.nama_kabupaten] += 1
      } else {
        mapData[item.nama_kabupaten] = 1
      }
      return item
    })
    rawData.labels = Object.keys(mapData)
    rawData.datasets[0].data = []
    rawData.labels.map(item => {
      rawData.datasets[0].data.push(((mapData[item]/data.length)*100).toFixed(1))
      return item
    })
    const sortedData = {
      ...rawData,
      labels: [...rawData.labels].sort((a, b) => rawData.datasets[0].data[rawData.labels.indexOf(b)] - rawData.datasets[0].data[rawData.labels.indexOf(a)]),
      datasets: rawData.datasets.map(dataset => ({
        ...dataset,
        data: [...dataset.data].sort((a, b) => b - a), // Sort data in descending order
      })),
    };
    setGraphData(sortedData)
  }, [data]);

  return (
    <BarChart isHorizontal={true} height={100} isXaxis={false} data={graphData} />
  )
}