import { useEffect, useState } from "react";
import BarChart from "../../charts/BarChart";
import BarStackChart from "../../charts/BarStackChart";

const labelFields = [
  'Beras',
  'Umbi',
  'Roti',
  'Ikan',
  'Ayam',
  'Hewan kaki empat',
  'Hewan buruan',
  'Telur',
  'Susu',
]
export default function PerubahanKonsumsi({ data }) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const mapData2 = {}
    const mapData3 = {}
    const mapData4 = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(4,135,55,1)',
          ],
          borderColor: [
            'rgba(4,135,55,1)',
          ],
          borderWidth: 1,
        },
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(92,114,74,1)',
          ],
          borderColor: [
            'rgba(92,114,74,1)',
          ],
          borderWidth: 1,
        },
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(163,182,138,1)',
          ],
          borderColor: [
            'rgba(163,182,138,1)',
          ],
          borderWidth: 1,
        },
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(199,183,147,1)',
          ],
          borderColor: [
            'rgba(199,183,147,1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    labelFields.map(field => {
      mapData[field] = 0
      mapData2[field] = 0
      mapData3[field] = 0
      mapData4[field] = 0
      return field
    })
    data.map(item => {
      if(item["frekuensi_beras"] === 1){
        if (mapData["Beras"] !== undefined) {
          mapData["Beras"] += 1
        }else{
          mapData["Beras"] = 1;
        }
      }
      if(parseInt(item["frekuensi_umbi"]) === 1){
        mapData["Umbi"] += 1
      }
      if(item["frekuensi_roti"] === 1){
        mapData["Roti"] += 1
      }
      if(item["frekuensi_ikan"] === 1){
        mapData["Ikan"] += 1
      }
      if(item["frekuensi_ayam"] === 1){
        mapData["Ayam"] += 1
      }
      if(item["frekuensi_kakiempat"] === 1){
        mapData["Hewan kaki empat"] += 1
      }
      if(item["frekuensi_hewanburuan"] === 1){
        mapData["Hewan buruan"] += 1
      }
      if(item["frekuensi_telur"] === 1){
        mapData["Telur"] += 1
      }
      if(item["frekuensi_susu"] === 1){
        mapData["Susu"] += 1
      }

      if(item["frekuensi_beras"] === 2){
          mapData2["Beras"] += 1
      }
if(item["frekuensi_umbi"] === 2){
        mapData2["Umbi"] += 1
      }
if(item["frekuensi_roti"] === 2){
        mapData2["Roti"] += 1
      }
if(item["frekuensi_ikan"] === 2){
        mapData2["Ikan"] += 1
      }
if(item["frekuensi_ayam"] === 2){
        mapData2["Ayam"] += 1
      }
if(item["frekuensi_kakiempat"] === 2){
        mapData2["Hewan kaki empat"] += 1
      }
if(item["frekuensi_hewanburuan"] === 2){
        mapData2["Hewan buruan"] += 1
      }
if(item["frekuensi_telur"] === 2){
        mapData2["Telur"] += 1
      }
if(item["frekuensi_susu"] === 2){
        mapData2["Susu"] += 1
      }

      if(item["frekuensi_beras"] === 3){
        mapData3["Beras"] += 1
      }
if(item["frekuensi_umbi"] === 3){
        mapData3["Umbi"] += 1
      }
if(item["frekuensi_roti"] === 3){
        mapData3["Roti"] += 1
      }
if(item["frekuensi_ikan"] === 3){
        mapData3["Ikan"] += 1
      }
if(item["frekuensi_ayam"] === 3){
        mapData3["Ayam"] += 1
      }
if(item["frekuensi_kakiempat"] === 3){
        mapData3["Hewan kaki empat"] += 1
      }
if(item["frekuensi_hewanburuan"] === 3){
        mapData3["Hewan buruan"] += 1
      }
if(item["frekuensi_telur"] === 3){
        mapData3["Telur"] += 1
      }
if(item["frekuensi_susu"] === 3){
        mapData3["Susu"] += 1
      }

      if(item["frekuensi_beras"] === 4){
        mapData4["Beras"] += 1
      }
if(item["frekuensi_umbi"] === 4){
        mapData4["Umbi"] += 1
      }
if(item["frekuensi_roti"] === 4){
        mapData4["Roti"] += 1
      }
if(item["frekuensi_ikan"] === 4){
        mapData4["Ikan"] += 1
      }
if(item["frekuensi_ayam"] === 4){
        mapData4["Ayam"] += 1
      }
if(item["frekuensi_kakiempat"] === 4){
        mapData4["Hewan kaki empat"] += 1
      }
if(item["frekuensi_hewanburuan"] === 4){
        mapData4["Hewan buruan"] += 1
      }
if(item["frekuensi_telur"] === 4){
        mapData4["Telur"] += 1
      }
if(item["frekuensi_susu"] === 4){
        mapData4["Susu"] += 1
      }
      return item
    })
    rawData.labels = Object.keys(mapData)
    rawData.datasets[0].data = []
    rawData.datasets[1].data = []
    rawData.datasets[2].data = []
    rawData.datasets[3].data = []
    rawData.labels.map(item => {
      rawData.datasets[0].label = 'Sama'
      rawData.datasets[1].label = 'Berkurang'
      rawData.datasets[2].label = 'Bertambah'
      rawData.datasets[3].label = 'Tidak Pernah'
      rawData.datasets[0].data.push(((mapData[item]/data.length)*100).toFixed(1))
      rawData.datasets[1].data.push(((mapData2[item]/data.length)*100).toFixed(1))
      rawData.datasets[2].data.push(((mapData3[item]/data.length)*100).toFixed(1))
      rawData.datasets[3].data.push(((mapData4[item]/data.length)*100).toFixed(1))
      return item
    })
    setGraphData(rawData)
  }, [data]);

  return (
    <BarStackChart
      height={130}
      isHorizontal={true}
      data={graphData}
    />
  )
}