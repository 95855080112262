import { useState, useEffect } from 'react';

const useDeviceOrientation = () => {
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  return { isMobile, isPortrait };
};

export default useDeviceOrientation;
