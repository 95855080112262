import { useEffect, useState } from "react";
import DoughnutChart from "../../charts/DoughnutChart";

const label = ['1-5', '6-10', '11-15']

export default function JumlahAnggotaKeluarga({ data, ...props }) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 0.8)',
            'rgba(4,135,55, 0.6)',
            'rgba(4,135,55, 0.3)',
            'rgba(4,135,55, 0.2)',
            'rgba(4,135,55, 0.1)',
          ],
          borderColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    label.map(item => {
      mapData[item] = 0
      return item
    })
    data.map(item => {
      if (item.jumlah_art && item.jumlah_art !== undefined) {
        if (item.jumlah_art >= 1 && item.jumlah_art <= 5) {
          mapData['1-5'] += 1
        }
        if (item.jumlah_art >= 6 && item.jumlah_art <= 10) {
          mapData['6-10'] += 1
        }
        if (item.jumlah_art >= 11 && item.jumlah_art <= 15) {
          mapData['11-15'] += 1
        }
      }
      return item
    })
    rawData.labels = Object.keys(mapData)
    rawData.datasets[0].data = []
    rawData.labels.map(item => {
      rawData.datasets[0].data.push(((mapData[item]/data.filter(item => Number.isInteger(item["jumlah_art"])).length)*100).toFixed(1))
      return item
    })
    setGraphData(rawData)
  }, [data]);

  return <div style={{ width: '250px', height: '250px', margin:"10px auto" }}><DoughnutChart data={graphData} { ...props } /></div>
}