import { useEffect, useState } from "react"
import BarChart from "../../charts/BarChart";

const keys = {
  'Kesuburan tanah': ["manfaat_hutan_kesuburan_tanah"],
  'Ketersediaan air': ["manfaat_hutan_ketersediaan_air"],
  'Mengatur iklim lokal': ["manfaat_hutan_mengatur_iklim_lokal"],
  'Naungan pohon': ["manfaat_hutan_naungan_pohon"],
  'Ketersediaan kayu bakar': ["manfaat_hutan_ketersediaan_kayu_bakar"],
}

const labelsWithLineBreaks = Object.keys(keys).map(label => label.split(' '));
export default function ManfaatHutan1({data}) {
  const [ManfaatHutan1, setManfaatHutan1] = useState({})
  useEffect(() => {
    const fields = Object.keys(keys)
    const rawData = []
    const mapData = {}
    fields.map(field => {
      mapData[field] = data.filter(item => item[keys[field][0]] === true).length
    })
    Object.keys(mapData).map(key => {
      rawData.push(((mapData[key]/data.filter(item => item["manfaat_hutan_kesuburan_tanah"] !== undefined).length) * 100).toFixed(1))
      return key
    })

    setManfaatHutan1(rawData)
  }, [data]);

  return (
    <>
      <BarChart
        height={200}
        isXaxis={false}
        isHorizontal={true}
        data={{
          labels: labelsWithLineBreaks,
          datasets: [
            {
              label: '',
              data: ManfaatHutan1,
              backgroundColor: [
                '#048737',
                '#048737',
                '#048737',
                '#048737',
                '#048737',
                '#048737',
              ],
            },
          ]
        }}
      />
    </>
  )
}
