import { useEffect, useState } from "react";

export default function PerubahanRekap({ data, field, fields = [], trueOrFalse = false, useTotalPembagi = false, unfilteredData }) {
  const [percentage, setPercentage] = useState(0)

  useEffect(() => {
    let totalBerubah = 0
    let totalPembagi = 0
    data.map(item => {
      if (!trueOrFalse) {
        if (!fields.length) {
          if (item[field] === 'berubah') {
            totalBerubah += 1
          }
        } else {
          fields.map(theField => {
            totalBerubah += 1
            return theField
          })
        }
      } else {
        if (!fields.length) {
          if (item[field] !== undefined) {
            totalPembagi += 1
            totalBerubah += item[field]
          }
        } else {
          if (item[fields[0]] !== undefined) {
            totalPembagi += 1
          }
          fields.map(theField => {
            if (item[theField] !== undefined) {
              totalBerubah += item[theField]
            }
            return theField
          })
        }
      }
      return item
    })
    let finalPembagi = data.length
    // let dataFinal = totalBerubah
    if (useTotalPembagi) {
       finalPembagi = unfilteredData.length
    // }else{
      //finalPembagi = data.filter(field => field["kategori dampak usaha"] == 'kesulitan').length
    }
    console.log(field, finalPembagi)
    setPercentage(((totalBerubah/finalPembagi)*100).toFixed(1))
  }, [data, field, fields, trueOrFalse, useTotalPembagi]);

  return (
    <div>
      <div><span className="text-[#048737] font-extrabold text-[52px]">{percentage}</span><span className="font-bold">%</span></div>
      {/* <div><span className={'p-1 text-[12px] rounded-[2px] text-white ' + ((100-parseFloat(percentage) < 50) ? 'bg-[#F36242]' : 'bg-[#6DDE6B]')}>{(100-parseFloat(percentage)).toFixed(1)}%</span> <span className="text-[#A0A0A0] text-[12px]">tidak berubah</span></div> */}
    </div>
  )
}