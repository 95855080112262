import { useEffect, useState } from "react";
import BarChart from "../../charts/BarChart";

const labelFields = {
  'Beras': 'komoditas_produksi_beras',
  'Buah segar': 'komoditas_produksi_buah_segar',
  'Perikanan segar': 'komoditas_produksi_hasil_perikanan_segar',
  'Perkebunan industri': 'komoditas_produksi_hasil_perkebunan_industri',
  'Ternak segar': 'komoditas_produksi_hasil_ternak_segar',
  'Hewan buruan': 'komoditas_produksi_hewan_buruan',
  'Kacang dan biji': 'komoditas_produksi_kacang_kacangan_dan_biji_bijian',
  'Makanan pokok': 'komoditas_produksi_makanan_pokok_selain_beras',
  'Olahan rumah tangga': 'komoditas_produksi_produk_olahan_rumah_tangga',
  'Sayur segar': 'komoditas_produksi_sayur_segar',
}
const labelsWithLineBreaks = Object.keys(labelFields).map(label => label.split(' '));
export default function KomoditasProduksi({ data }) {
  const [graphData, setGraphData] = useState({
    labels: labelsWithLineBreaks,
    datasets: []
  })

  useEffect(() => {
    const rawData = {
      labels: labelsWithLineBreaks,
      datasets: [
        {
          label: '',
          data: [],
          backgroundColor: [
            '#048737',
            '#048737',
            '#048737',
            '#048737',
            '#048737',
            '#048737',
          ],
        },
      ]
    }
    const fields = Object.values(labelFields)
    const fieldsObj = {}
    fields.map(field => {
      fieldsObj[field] = 0
      return field
    })
    data.map(item => {
      fields.map(field => {
        if (item[field] !== undefined) {
          fieldsObj[field] += item[field]
        }
        return field
      })
      return item
    })
    fields.map(field => {
      rawData.datasets[0].data.push(((fieldsObj[field]/data.length) * 100).toFixed(1))
      return field
    })
    const sortedData = {
      ...rawData,
      labels: [...rawData.labels].sort((a, b) => rawData.datasets[0].data[rawData.labels.indexOf(b)] - rawData.datasets[0].data[rawData.labels.indexOf(a)]),
      datasets: rawData.datasets.map(dataset => ({
        ...dataset,
        data: [...dataset.data].sort((a, b) => b - a), // Sort data in descending order
      })),
    };
    setGraphData(sortedData)
  }, [data]);
  const options = {
    plugins: {
      legend: {
        labels: {
          font: {
            size: 'auto' // Set label font size to auto
          }
        }
      }
    }
  };
  return (
    <BarChart
      isHorizontal={true}
      height={500}
      isXaxis={false} 
      data={graphData}
    />
  )
}