import { useEffect, useState } from "react";
import BarChart from "../../charts/BarChart";
const labelFields = [
  'Pemerintah',
  'LSM',
  'Swasta',
  'Lembaga agama',
  'Koperasi',
  'Perbankan',
  'Lainnya'
]
export default function PemberiBantuan({ data }) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 0.8)',
            'rgba(4,135,55, 0.6)',
            'rgba(4,135,55, 0.3)',
            'rgba(4,135,55, 0.2)',
            'rgba(4,135,55, 0.1)',
          ],
          borderColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    labelFields.map(field => {
      mapData[field] = 0
      return field
    })
    data.map(item => {
      if(item["bantuan_pemerintah"] === 1){
        mapData["Pemerintah"] += 1
      }
      if(item["bantuan_lsm"] === 1){
        mapData["LSM"] += 1
      }
      if(item["bantuan_swasta"] === 1){
        mapData["Swasta"] += 1
      }
      if(item["bantuan_lembaga_keagamaan"] === 1){
        mapData["Lembaga agama"] += 1
      }
      if(item["bantuan_koperasi"] === 1){
        mapData["Koperasi"] += 1
      }
      if(item["bantuan_perbankan"] === 1){
        mapData["Perbankan"] += 1
      }
      if(item["bantuan_lainnya"] === 1){
        mapData["Lainnya"] += 1
      }
      return item
    })
    rawData.labels = Object.keys(mapData)
    rawData.datasets[0].data = []
    rawData.labels.map(item => {
      rawData.datasets[0].data.push(((mapData[item]/data.filter(item => Number.isInteger(item["bantuan_pemerintah"])).length)*100).toFixed(1))
      return item
    })
    setGraphData(rawData)
  }, [data]);

  return (
    <BarChart
      height={250}
      isXaxis={false}
      isHorizontal={true}
      data={graphData}
    />
  )
}