import { useEffect, useState } from "react";
import BarChart from "../../charts/BarChart";

const labelFields = {
  'Fakfak': 'lokasi_produksi_Fakfak',
  'Kaimana': "lokasi_produksi_Kaimana ",
  'Kota Sorong': 'lokasi_produksi_Kota Sorong',
  'Manokwari': "lokasi_produksi_Manokwari ",
  'Manokwari Selatan': "lokasi_produksi_Manokwari Selatan ",
  'Maybrat': "lokasi_produksi_Maybrat ",
  'Pegunungan Arfak': "lokasi_produksi_Pegunungan Arfak ",
  'Raja Ampat': 'lokasi_produksi_Raja Ampat',
  'Sorong': "lokasi_produksi_Sorong ",
  'Sorong Selatan': "lokasi_produksi_Sorong Selatan ",
  'Tambraw': 'lokasi_produksi_Tambraw',
  'Teluk Bintuni': "lokasi_produksi_Teluk Bintuni ",
  'Teluk Wondama': "lokasi_produksi_Teluk Wondama ",
  'Lainnya': 'lokasi_produksi_Lainnya',
}
const labelsWithLineBreaks = Object.keys(labelFields).map(label => label.split(' '));

export default function JumlahDanLokasiProduksi({ data }) {
  const [graphData, setGraphData] = useState({
    labels: labelsWithLineBreaks,
    datasets: []
  })

  const [jumlahLokasi, setJumlahLokasi] = useState(0);
  useEffect(() => {
    const rawData = {
      labels: labelsWithLineBreaks,
      datasets: [
        {
          label: '',
          data: [],
          backgroundColor: [
            '#048737',
            '#048737',
            '#048737',
            '#048737',
            '#048737',
            '#048737',
          ],
        },
      ]
    }
    const fields = Object.values(labelFields)
    const fieldsObj = {}
    fields.map(field => {
      fieldsObj[field] = 0
      return field
    })
    data.map(item => {
      fields.map(field => {
        if (item[field] !== undefined) {
          fieldsObj[field] += item[field]
        }
        return field
      })
      return item
    })
    fields.map(field => {
      rawData.datasets[0].data.push(((fieldsObj[field]/data.length) * 100).toFixed(1))
      return field
    })
    const sortedData = {
      ...rawData,
      labels: [...rawData.labels].sort((a, b) => rawData.datasets[0].data[rawData.labels.indexOf(b)] - rawData.datasets[0].data[rawData.labels.indexOf(a)]),
      datasets: rawData.datasets.map(dataset => ({
        ...dataset,
        data: [...dataset.data].sort((a, b) => b - a), // Sort data in descending order
      })),
    };
    setGraphData(sortedData)
    setJumlahLokasi(((data.filter(field => field.jumlah_lokasi_produksi == 1).length / data.length) * 100).toFixed(1))
  }, [data]);

  return (
    <div>
    <div>
      <div><span className="text-[#048737] font-extrabold text-[32px]">{jumlahLokasi}</span><span className="font-bold">%</span></div>
      <div> <span className="text-[#A0A0A0] text-[12px]">1 Lokasi produksi</span></div>
    </div>
    <BarChart
      isHorizontal={true}
      height={480}
      isXaxis={false} 
      data={graphData}
    /></div>
  )
}