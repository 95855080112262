export default function Card({ children, title = '', classes = '', tooltip, ...props }) {
  return (
    <div className={'relative px-[23px] py-[21px] bg-white rounded-[6px] border-solid border-1 border-[#E2E2E2] ' + classes} { ...props }>
      <div className="flex w-full mb-[11px]">
        {title && <h4 className="text-black font-bold leading-[25px]">{title}</h4>}
        {tooltip && <span className="tooltip">{tooltip}</span>}
      </div>
      {children}
    </div>
  )
}
