import { Routes, Route, Outlet, NavLink, useLocation, Link, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import SurveyKonsumenPangan from "./pages/SurveyKonsumenPangan";
import TentangSurvey from "./pages/TentangSurvey";
import Tim from "./pages/Tim";
import Bantuan from "./pages/Bantuan";
import IconBreadcrumb from "./components/misc/IconBreadcrumb";
import Button from "./components/misc/Button";
import { useState } from "react";
import IconClose from "./components/misc/IconClose";
import SurveyProdusenPangan from "./pages/SurveyProdusenPangan";
import Depan from "./pages/Depan";
import ScrollToTop from "./components/ScrollToTop";
import useDeviceOrientation from './useDeviceOrientation';
import ReactGA from 'react-ga4';

const links = [
  {
    path: '/depan',
    name: 'Depan'
  },
  {
    path: '/survey/pelaku-rantai-pasok-pangan',
    name: 'Survei Pelaku Rantai Pasok Pangan'
  },
  {
    path: '/survey/produsen-pangan',
    name: 'Survei Produsen Pangan'
  },
  {
    path: '/survey/konsumen-pangan',
    name: 'Survei Konsumen Pangan'
  }
]

const pages = [
  {
    path: '/depan',
    name: 'Beranda'
  },
  {
    path: '/bantuan',
    name: 'Panduan Penggunaan'
  }
]

const partners = [
  {
    name: 'CIFOR-ICRAF',
    image: '/partners/cifor-icraf.png'
  },
  {
    name: 'Papua Barat',
    image: '/partners/papua-barat.png'
  },
  {
    name: 'USAID',
    image: '/partners/usaid.png'
  },
  {
    name: 'FCDO',
    image: '/partners/fcdo.png'
  },
  {
    name: 'Nutriscape',
    image: '/partners/nutriscape.png'
  }
]

function App() {
  const { isMobile, isPortrait } = useDeviceOrientation();
  ReactGA.initialize('G-BSVQKXW93J');

  return (
    <>
      {isMobile && isPortrait ? (
        <div className="flex flex-col items-center aligns-center" style={{justifyContent : 'center', height: '100vh'}}>
          <img src="/rotate.png" alt="Rotate" style={{ width: '40%' }}/>
          <p className='prose mt-4 text-center' style={{fontSize: 12, width: '100%', maxWidth: '100%'}}>Rotasi gawai anda ke mode landscape</p>
        </div>
      ) : (
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="depan" element={<Depan />} />
            <Route path="survey" element={<LayoutSurvey />}>
              <Route path="pelaku-rantai-pasok-pangan" element={<Home />} />
              <Route path="produsen-pangan" element={<SurveyProdusenPangan />} />
              <Route path="konsumen-pangan" element={<SurveyKonsumenPangan />} />
            </Route>
            <Route path="tentang-survey" element={<TentangSurvey />} />
            <Route path="tim" element={<Tim />} />
            <Route path="bantuan" element={<Bantuan />} />
          </Route>
        </Routes>
      )}
    </>
  );
}

function Layout() {
  const location = useLocation()
  const { pathname } = location
  const hasAside = pathname.includes('/survey/')
  const isFull = pathname.includes('/depan')
  return (
    <div className='bg-[#F5F5F5] min-h-screen'>
      {
        (pathname === '/survey' || pathname === '/') && <Navigate to={links[0].path} replace={true} />
      }
      <header id='masthead' className='sticky top-0 bg-white shadow py-[11px] z-10'>
        <div className='container mx-auto px-[15px]'>
          <div className="block md:flex items-center">
            <Link to={'/'} className="md:mb-0 mb-2 block">
              <h1 className="font-bold text-[17px] text-[#231F20]">Survei sistem pangan</h1>
              <h3 className="text-[17px] font-light text-[#231F20]">2020</h3>
            </Link>
            <div className="grow"></div>
            <nav>
              <ul className="flex gap-8">
                {pages.map((item, key) => {
                  return (
                    <li className="block" key={key}>
                      <NavLink
                        className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}
                        to={item.path}>
                        {item.name}
                      </NavLink>
                    </li>
                  )
                })}
              </ul>
            </nav>
          </div>
        </div>
      </header>

      <main className={'min-h-full ' + (hasAside ? 'md:pl-[250px] xl:pl-[250px]' : '') + pathname.replaceAll("/","")}>
        <div className={isFull ? "mx-auto relative" : "container mx-auto py-[15px] px-[15px] md:py-[53px] md:px-[40px] relative"}>
          <Outlet />
        </div>
      </main>

      <footer className='bg-[#033717] relative z-[3] text-[#DBDBDB] text-[16px] py-8 md:py-[20px]'>
        <div className='container mx-auto py-[15px] px-[15px]'>
          © 2022 - Transform Platform / All Rights Resevered
        </div>
      </footer>
    </div>
  )
}

function LayoutSurvey() {
  const [showAside, setShowAside] = useState(true)
  return (
    <>
      <div className='block md:hidden'>
        <Button style={{ padding: '4px' }} onClick={() => setShowAside(!showAside)}>
          {
            !showAside ? <IconBreadcrumb /> : <IconClose />
          }
        </Button>
      </div>
      <aside className={
        'sidebar-menu static md:fixed left-0 top-0 md:pt-[105px] z-[2] h-full hidden md:flex flex-col bg-white md:w-[250px] md:mb-0 mb-8 py-2 md:py-[53px] pr-[11px] ' +
        (showAside ? '!flex' : '!hidden')
      }>
        <nav>
          <ul>
            {links.map((item, key) => {

            if(item.name != 'Depan' ){
              return (
                <li className="block mb-4" key={key}>
                  <NavLink
                    className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}
                    to={item.path} style={{display: 'flex', alignItems: 'center'}}>
                      <img src={"/s"+key+"-green.png"} alt="image" style={{height : '30px', marginRight: '10px'}}/>
                    <span>{item.name}</span>
                  </NavLink>
                </li>
              )
              }
            })}
          </ul>
        </nav>
        <div className="grow"></div>
        <div className="p-6">
          <div className="text-neutral-700 text-[13px] font-normal leading-7 mb-4">Mitra</div>
          <div className="w-full partner">
            {partners.map((item, key) => {
              return (
                <img key={key} src={item.image} alt={item.name} className="shrink-0 h-auto w-auto inline-block mr-4 mb-4" style={{maxHeight: '30px'}}/>
              )
            })}
          </div>
        </div>
      </aside>
      <Outlet />
    </>
  )
}

export default App;
