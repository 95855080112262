import { useEffect, useState } from "react";

export default function PenggunaanPangan({ data, field, fields = [], trueOrFalse = false, useTotalPembagi = false }) {
  const [percentage, setPercentage] = useState(0)

  useEffect(() => {
    let totalBerubah = 0
    data.map(item => {
      if(typeof item[field] === 'number'){
        totalBerubah += item[field];
      }
      return item
    })
    //let finalPembagi = data.length
    setPercentage((totalBerubah/data.filter(item => typeof item[field] === 'number').length).toFixed(1))
  }, [data, field, fields, trueOrFalse, useTotalPembagi]);

  return (
    <div>
      <div><span className="text-[#048737] font-extrabold text-[48px]">{percentage}</span><span className="font-bold">%</span></div>
      <div><span className="text-[#ffffff] text-[12px]">-</span></div>
    </div>
  )
}