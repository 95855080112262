import React, { useEffect, useState } from 'react';
import { read, utils } from 'xlsx';
import AsalKabupaten from '../components/data-graph/AsalKabupaten';
import PendidikanTerakhir from '../components/data-graph/PendidikanTerakhir';
import Card from '../components/misc/Card';
import Peran from '../components/data-graph/Peran';
import ProdukUsaha from '../components/data-graph/ProdukUsaha';
import PerubahanPendapatan from '../components/data-graph/PerubahanPendapatan';
import PerubahanRekap from '../components/data-graph/PerubahanRekap';
import StrategiResponden from '../components/data-graph/StrategiResponden';
import BantuanUsaha from '../components/data-graph/BantuanUsaha';
import KategoriDampakUsaha from '../components/data-graph/KategoriDampakUsaha';
import Button from '../components/misc/Button';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import LoadingOverlay from 'react-loading-overlay';
import ClipLoader from 'react-spinners/ClipLoader'
import Carousel, {Modal, ModalGateway} from "react-images";
import ReactGA from "react-ga4";

export default function Home() {
  ReactGA.send({ hitType: "pageview", page: "/survey/pelaku-rantai-pasok-pangan", title: "Survei Pelaku Rantai Pasok Pangan" });

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [filterJk, setFilterJk] = useState(null) // perempuan
  const [filterSuku2, setFilterSuku2] = useState(null) // OAP
  const [currentImage, setCurrentImage] = useState(0);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);

  useEffect(() => { (async() => {
    const f = await fetch("/Final_DB_Survey 1_PB.xlsx");
    const ab = await f.arrayBuffer();
    const wb = read(ab);
    const ws = wb.Sheets[wb.SheetNames[1]];
    const rawData = utils.sheet_to_json(ws);
    setLoading(false)
    setData(rawData)
  })(); }, [])

  useEffect(() => {
    const rawData = data.filter(item => {
      // return item.sex === filterJk && item.kategori_suku_dua === filterSuku2
      if (filterJk && filterSuku2) {
        return item.sex === filterJk && item.kategori_suku_dua === filterSuku2
      } else if (filterJk && !filterSuku2) {
        return item.sex === filterJk
      } else if (!filterJk && filterSuku2) {
        return item.kategori_suku_dua === filterSuku2
      } else {
        return item
      }
    })
    setFilteredData(rawData)
  }, [data, filterJk, filterSuku2]);


  const images = [
    { src: '/foto/survey-covid-papua-barat-sebaran-responden-2.png',caption: "Survey Covid Papua Barat sebaran responden 2"},
    // Add more images as needed
  ];

  const openLightbox = (index) => {
    setCurrentImage(index);
    setLightboxIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setLightboxIsOpen(false);
  };

  console.log('data filtered: ',filteredData.filter(item =>Number.isInteger(item["dampak_kesulitan_penjualan"])).length)
  return (
    <LoadingOverlay
    active={loading}
    spinner={<ClipLoader color="#36d7b7" size="70"/>}
    >
      <h2 className="text-stone-800 mb-[25px] text-[39px] font-bold">Survei Pelaku Rantai Pasok Pangan</h2>
      <div className='xl:flex w-full mb-[77px]'>
        <div className="w-full text-neutral-700 mb-8 xl:mb-0 text-[17px] leading-7 xl:w-[70%]">
          <p className='mb-4'>Survei pelaku rantai pasok pangan ini dilakukan kepada semua orang/individu yang terlibat secara langsung dalam proses produksi (menghasilkan, menyiapkan, mengolah, membuat, mengawetkan, mengemas, mengemas kembali, dan/atau mengubah bentuk pangan), penyaluran/distribusi, pengangkutan, penjualan, pemasaran, pangan jenis apa saja (mentah, segar, olahan, pabrikan) dan bahan pendukung produksi pangan jenis apa saja (bibit, pestisida, kemasan makanan, dan lain-lain). </p>
          <p>Tujuan dari survei ini adalah untuk mendapatkan informasi mengenai:</p>
          1.	Pelaku dan produk dalam rantai pasok pangan di Papua Barat <br></br>
          2.	Gangguan dalam rantai pasok pangan akibat COVID-19<br></br>
          3.	Strategi pelaku rantai pasok pangan dalam menghadapi COVID-19<br></br>
          <p className='mb-4'></p>
          <p className='mb-4'>Keluaran dari survei ini diharapkan dapat memberikan informasi kepada pemerintah sehingga dapat merencanakan kebijakan/program yang membantu pelaku rantai pasok pangan di Provinsi Papua Barat.</p>
          <p>Survei ini dilaksanakan secara semi-online pada bulan September sampai dengan Desember 2020 di <a onClick={() => openLightbox(0)}  style={{cursor: "pointer"}}  className='underline'>7 Kabupaten</a> yaitu Manokwari, Manokwari Selatan, Maybrat, Pegunungan Arfak, Teluk Bintuni, dan Teluk Wondama (Maybrat saat ini masuk ke Provinsi Papua Barat Daya). Kami mengedarkan tautan survei online melalui grup <i>chat</i>, saluran media sosial, dan mengirimkan surat resmi ke lembaga terkait pangan dan pertanian untuk meminta mereka membantu mengedarkan tautan tersebut. Selain itu, kami menggabungkan upaya ini dengan melakukan kunjungan enumerator ke; pasar, distributor, warung makan, kios, restoran, kafe, peternakan, dan lain-lain untuk meminta pelaku rantai pasok pangan berpartisipasi dalam survei. Total responden dalam survei pelaku rantai pasok pangan sebanyak 423 responden yang terdiri dari 190 laki-laki dan 233 perempuan dimana 217 responden merupakan Orang Asli Papua (OAP) dan 206 lainnya merupakan non OAP (bukan Orang Asli Papua). Sebagai tambahan, OAP Perempuan sebanyak 158 orang, OAP Laki-laki 59 orang, Non-OAP Perempuan sebanyak 75 orang, dan Non-OAP Laki-laki sebanyak 158 orang.</p>
          <ModalGateway>
            {lightboxIsOpen ? (
                <Modal onClose={closeLightbox}>
                  <Carousel views={images} />
                </Modal>
            ) : null}
          </ModalGateway>
        </div>
        <div className='w-full text-black text-sm font-normal leading-[25px] xl:pl-[80px] xl:w-[30%]'>
          <Tooltip 
          id="my-tooltip"
          className={'opaque'}
          style={{ backgroundColor: "#ffffff", color: "#222", opacity: 1 }}>
            <p className='mb-4 text-base'>Cara menggunakan infografis:</p>
            <div className='prose'>
            <ol>
              <li>Setiap kelompok infografis menggambarkan setiap tujuan dalam survei </li>
              <li>Tombol jenis kelamin dan kategori suku merupakan filter yang dapat digunakan jika ingin mengetahui persentase untuk masing-masing pilihan jenis kelamin dan atau kategori suku. OAP adalah Orang Asli Papua dan Non OAP adalah bukan Orang Asli Papua</li>
              <li>Setiap filter yang dipilih, maka data yang ditampilkan adalah persentase berdasarkan jumlah OAP/non OAP/laki-laki/perempuan. </li>
            </ol>
            </div>
          </Tooltip>
          <button className="button w-full" data-tooltip-id="my-tooltip">PANDUAN PENGGUNAAN</button>
        </div>
      </div>

      <div className='xl:flex items-center w-full gap-x-12 py-8 mb-8 sticky bg-[#F5F5F5] top-[105px] md:top-[72px] z-10 border-t border-t-zinc-300'>
        <div className='flex gap-4 mr-24'>
          <div className='font-bold'>Suku</div>
          <div className='form-control'>
            <input checked={filterSuku2 === 'non OAP'} className='mr-1' type='radio' name='suku' id='non-oap' value='non OAP' onChange={(e) => setFilterSuku2(e.target.value)} />
            <label htmlFor="non-oap">non OAP</label>
          </div>
          <div className='form-control'>
            <input checked={filterSuku2 === 'OAP'} className='mr-1' type='radio' name='suku' id='oap' value='OAP' onChange={(e) => setFilterSuku2(e.target.value)} />
            <label htmlFor="oap">OAP</label>
          </div>
        </div>
        <div className='flex gap-4'>
          <div className='mr-1 font-bold'>Jenis kelamin</div>
          <div className='form-control'>
            <input checked={filterJk === 'laki-laki'} className='mr-1' type='radio' name='filter-jk' id='laki-laki' value='laki-laki' onChange={(e) => setFilterJk(e.target.value)} />
            <label htmlFor="laki-laki">Laki-laki</label>
          </div>
          <div className='form-control'>
            <input checked={filterJk === 'perempuan'} className='mr-1' type='radio' name='filter-jk' id='perempuan' value='perempuan' onChange={(e) => setFilterJk(e.target.value)} />
            <label htmlFor="perempuan">Perempuan</label>
          </div>
        </div>
        <div className='grow'></div>
        {
          (filterJk || filterSuku2) &&
            <Button
              style={{ padding: '10px', fontSize: '10px' }}
              onClick={() => {
                setFilterJk(null)
                setFilterSuku2(null)
              }
            }>
            Reset
          </Button>
        }
      </div>

      {!loading && 
        <>
          <div className='text-stone-800 text-lg font-bold leading-[18px] mb-[34px]' style={{fontSize: 22, color : '#013b09'}}>
          Pelaku dan Produk dalam Rantai Pasok Pangan di Papua Barat
          </div>
          <div className='block xl:flex gap-8 w-full mb-8'>
            <Card
              classes='mb-8 xl:mb-0 xl:w-[70%] pb-12'>
              <div className='w-full'>
                <div style={{ width: '100%  ', margin:"10px auto" }}>
                  <h4 className="text-black font-bold leading-[25px]">Peran Responden</h4>
                  <div className='prose mt-2' style={{fontSize: 12}}>Posisi/pekerjaan responden dalam rantai pasok pangan</div>
                  <Peran data={filteredData} unfilteredData={data} />
                </div>
                <div style={{ width: '100%', height: '400px', margin:"10px auto" }}>
                  <h4 className="text-black font-bold leading-[25px] mt-2">Produk Usaha Responden</h4>
                  <div className='prose mt-2' style={{fontSize: 12}}>Komoditas yang dibuat/dijual/didistribusikan oleh responden</div>
                  <ProdukUsaha data={filteredData} unfilteredData={data} />
                </div>
              </div>
            </Card>

            <div className='grow flex flex-col gap-8 xl:w-[30%]'>
              <Card title='Pendidikan Terakhir'>
                <PendidikanTerakhir data={filteredData} />
              </Card>

              <Card title='Kabupaten Tempat Tinggal' classes='grow'>
              <div className='mt-10'></div>
                <AsalKabupaten data={filteredData} />
              </Card>
            </div>
          </div>

          <div className='text-stone-800 text-lg font-bold leading-[18px] mb-[34px]' style={{fontSize: 22, color : '#013b09'}}>
          Gangguan dalam Rantai Pasok Pangan akibat COVID-19
          </div>
          <Card style={{marginBottom :"30px"}}>
            <div className='block xl:flex gap-8 w-full'>
              <div className='xl:w-[40%]'>
                  <div className='md:grid md:grid-cols-2 md:gap-2 mb-2'>
                    <Card title='Perubahan Pendapatan' className="relative bg-white rounded-[6px]">
                    <PerubahanRekap field={'perubahan_pendapatan'} data={filteredData} />
                    </Card>
                    <Card title='Perubahan Peran' className="relative bg-white rounded-[6px]">
                      <PerubahanRekap field={'perubahan_peran_setelah'} data={filteredData} />
                    </Card>
                  </div>
                  <div className='md:grid md:grid-cols-2 md:gap-2 mb-2'>
                    <Card title='Perubahan Produk' className="relative bg-white rounded-[6px]">
                      <PerubahanRekap field={'perubahan_jenis_produk_setelah'} data={filteredData} />
                    </Card>
                    <Card title='Perubahan Lokasi' className="relative bg-white rounded-[6px]">
                      <PerubahanRekap field={'perubahan_lokasi_setelah'} data={filteredData} />
                    </Card>
                  </div>
              </div>
              <div className='xl:w-[60%]'>
                  <h4 className="text-black font-bold leading-[25px]">Banyaknya Perubahan Pendapatan</h4>
                  <PerubahanPendapatan data={filteredData} />
              </div>
            </div>
          </Card>
          <Card style={{marginBottom :"30px"}}>
          <div className='block xl:flex gap-8 w-full'>
            <div className='xl:w-[30%]'>
              <Card title='Dampak COVID-19 terhadap Kegiatan Usaha'
                    className="relative bg-white rounded-[6px]">
                <div style={{ width: '350px', height: '400px', margin:"30px auto" }}>
                <KategoriDampakUsaha data={filteredData} />
                </div>
              </Card>
            </div>
            <div className='xl:w-[70%] xl:mb-0 mb-8'>
              <div className='md:grid md:grid-cols-2 md:gap-8'>
                <Card title='Penjualan Menurun*'
                    className="relative bg-white rounded-[6px]">
                  <PerubahanRekap
                   className="relative bg-white rounded-[6px]"
                    field={'dampak_kesulitan_penjualan'}
                    useTotalPembagi={false}
                    data={filteredData.filter(item => Number.isInteger(item["dampak_kesulitan_penjualan"]))}
                    trueOrFalse={true}
                    unfilteredData={data}
                  />
                </Card>
                <Card title='Pembeli Susah Didapat*'
                    className="relative bg-white rounded-[6px]">
                  <PerubahanRekap
                    field={'dampak_kesulitan_pembeli'}
                    useTotalPembagi={false}
                    data={filteredData.filter(item => Number.isInteger(item["dampak_kesulitan_pembeli"]))}
                    trueOrFalse={true}
                    unfilteredData={data}
                  />
                </Card>
                <Card title='Pasokan Bahan Menurun*'
                    className="relative bg-white rounded-[6px]">
                  <PerubahanRekap
                    fields={['dampak_kesulitan_bahanbaku', 'dampak_kesulitan_bahanpendukung']}
                    useTotalPembagi={false}
                    data={filteredData.filter(item => 
                      Number.isInteger(item["dampak_kesulitan_bahanbaku"]) || Number.isInteger(item["dampak_kesulitan_bahanpendukung"]))}
                    trueOrFalse={true}
                    unfilteredData={data}
                  />
                </Card>
                <Card title='Peyimpanan dan Pengangkutan Sulit*'
                    className="relative bg-white rounded-[6px]">
                  <PerubahanRekap
                    fields={['dampak_kesulitan_penyimpanan', 'dampak_kesulitan_pengangkutan']}
                    useTotalPembagi={false}
                    data={filteredData.filter(item => Number.isInteger(item["dampak_kesulitan_penyimpanan"]) || Number.isInteger(item["dampak_kesulitan_pengangkutan"]))}
                    trueOrFalse={true}
                    unfilteredData={data}
                  />
                </Card>
                <Card title='Harga Turun dan Banyak Sisa*'
                    className="relative bg-white rounded-[6px]">
                  <PerubahanRekap
                    fields={['dampak_kesulitan_harga_turun', 'dampak_kesulitan_banyaksisa']}
                    useTotalPembagi={false}
                    data={filteredData.filter(item => Number.isInteger(item["dampak_kesulitan_harga_turun"]) || Number.isInteger(item["dampak_kesulitan_banyaksisa"]))}
                    trueOrFalse={true}
                    unfilteredData={data}
                  />
                </Card>
                <Card title='Lainnya*'
                    className="relative bg-white rounded-[6px]">
                  <PerubahanRekap
                    fields={['dampak_kesulitan_modal', 'dampak_kesulitan_pemasaran','dampak_kesulitan_pekerja', 'dampak_kesulitan_lainnya']}
                    useTotalPembagi={false}
                    data={filteredData.filter(item => 
                      Number.isInteger(item["dampak_kesulitan_modal"]) || 
                      Number.isInteger(item["dampak_kesulitan_pemasaran"]) || 
                      Number.isInteger(item["dampak_kesulitan_pekerja"]) || 
                      Number.isInteger(item["dampak_kesulitan_lainnya"]))}
                    trueOrFalse={true}
                    unfilteredData={data}
                  />
                </Card>
              </div>
              <div className='prose mt-4' style={{fontSize: 12, width: '100%', maxWidth: '100%'}}>
                *: Jumlah responden yang menjawab kesulitan dan untung & rugi berjumlah 387 yang terdiri dari 167 laki-laki dan 220 perempuan dimana 203 responden merupakan OAP dan 184 responden merupakan non OAP
              </div>
            </div>

          </div>
          </Card>


          <div className='mt-4 mb-8'>
            <div className='flex py-[21px] px-[23px] rounded-[6px]' style={{background: '#fff'}}>
              <div className='xl:w-[50%]'>
                <div className='block xl:flex gap-8 w-full'>
                  <img src="/Picture2.jpg" alt="pic"  style={{width: '140px', height: '100px'}}/>
                  <blockquote className='prose' style={{width: '100%'}}>
                    Seorang nelayan dari Manokwari mengeluh:<i> "Kesulitan kami itu karena penjualan ikan kami yang sulit karena tidak ada yang membeli seperti biasanya. Siap kami menjual itu, jika akses transportasi penjualan kami terbuka dan biaya transportasinya murah, maka semuanya itu akan baik-baik saja .. "</i>
                  </blockquote>
                </div>
              </div>
              <div className='xl:w-[50%]'>
                <div className='block xl:flex gap-8 w-full'>
                  <img src="/Picture1.jpg" alt="pic" style={{width: '140px', height: '100px'}}/>
                  <blockquote className='prose' style={{width: '100%'}}>
                    Seorang petani perempuan dari Teluk Bintuni juga mengeluh <i>"Kesulitan kami dipasar ini karena pembeli, sayur yang kami jual kadang kami bawa pulang lagi, mau simpan juga nanti busuk, kadang kami harus buang. Waktu penjualan kami terbatas, sehingga kami tidak bisa berjualan secara maksimal"</i>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>

          <div className='text-stone-800 text-lg font-bold leading-[18px] mb-[34px]' style={{fontSize: 22, color : '#013b09'}}>
            Strategi Pelaku Rantai Pasok Pangan dalam Menghadapi COVID-19
          </div>
          <div className='block xl:flex gap-8 w-full mb-8'>
            <Card title='Strategi yang Dilakukan Responden*' classes='xl:w-[100%] xl:mb-0 mb-8'>
              <StrategiResponden data={filteredData} unfilteredData={data}/>
              <div className='prose mt-4' style={{fontSize: 12, width: '100%', maxWidth: '100%'}}>
                *: Jumlah responden yang menjawab adalah 420 orang <br />
                **: Lainnya termasuk strategi dalam (dan atau) penyesuaian distribusi, transportasi, modal, dan lainnya
              </div>
            </Card>
          </div>

          <div className='block xl:flex gap-8 w-full mb-8'>
            <div className='xl:w-[100%] xl:mb-0 mb-8'>
              <Card title='Bantuan Usaha, termasuk Pemberi Bantuan dan Bentuknya'>
                <BantuanUsaha data={filteredData} unfilteredData={data}/>
              </Card>
            </div>
          </div>
        </>
      }
    </LoadingOverlay>
  );
}

