import { useEffect, useState } from "react";
import LineChart from "../../charts/LineChart";

const labelFields = ['<50k',
  '50–100k',
  '100–300k',
  '300–500k',
  '>500k',
  'Tidak menjawab',
]
export default function PengeluaranPangan({ data }) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const mapData2 = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(4,135,55,1)',
            'rgba(4,135,55, 0.8)',
            'rgba(4,135,55, 0.6)',
            'rgba(4,135,55, 0.3)',
            'rgba(92,114,74)',
            'rgba(4,135,55,1))',
          ],
          borderColor: [
            'rgba(4,135,55,1)',
            'rgba(4,135,55,1)',
            'rgba(4,135,55,1)',
            'rgba(4,135,55,1)',
            'rgba(4,135,55,1)',
            'rgba(4,135,55,1)',
          ],
          borderWidth: 0,
        },
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(199,183,147, 1)',
          ],
          borderColor: [
            'rgba(199,183,147, 1)',
          ],
          borderWidth: 0,
        },
      ],
    };
    labelFields.map(field => {
      mapData[field] = 0
      mapData2[field] = 0
      return field
    })
    data.map(item => {
      if(item["pengeluaran_pangan_sebelum"] === 1){
        if (mapData["<50k"] !== undefined) {
          mapData["<50k"] += 1
        }else{
          mapData["<50k"] = 1;
        }
      }else if(item["pengeluaran_pangan_sebelum"] === 2){
        if (mapData["50–100k"] !== undefined) {
          mapData["50–100k"] += 1
        }else{
          mapData["50–100k"] = 1;
        }
      }else if(item["pengeluaran_pangan_sebelum"] === 3){
        if (mapData["100–300k"] !== undefined) {
          mapData["100–300k"] += 1
        }else{
          mapData["100–300k"] = 1;
        }
      }else if(item["pengeluaran_pangan_sebelum"] === 4){
        if (mapData["300–500k"] !== undefined) {
          mapData["300–500k"] += 1
        }else{
          mapData["300–500k"] = 1;
        }
      }else if(item["pengeluaran_pangan_sebelum"] === 5){
        if (mapData[">500k"] !== undefined) {
          mapData[">500k"] += 1
        }else{
          mapData[">500k"] = 1;
        }
      }else if(item["pengeluaran_pangan_sebelum"] === 6){
        if (mapData["Tidak menjawab"] !== undefined) {
          mapData["Tidak menjawab"] += 1
        }else{
          mapData["Tidak menjawab"] = 1;
        }
      }

      if(item["pengeluaran_pangan_setelah"] === 1){
        if (mapData2["<50k"] !== undefined) {
          mapData2["<50k"] += 1
        }else{
          mapData2["<50k"] = 1;
        }
      }else if(item["pengeluaran_pangan_setelah"] === 2){
        if (mapData2["50–100k"] !== undefined) {
          mapData2["50–100k"] += 1
        }else{
          mapData2["50–100k"] = 1;
        }
      }else if(item["pengeluaran_pangan_setelah"] === 3){
        if (mapData2["100–300k"] !== undefined) {
          mapData2["100–300k"] += 1
        }else{
          mapData2["100–300k"] = 1;
        }
      }else if(item["pengeluaran_pangan_setelah"] === 4){
        if (mapData2["300–500k"] !== undefined) {
          mapData2["300–500k"] += 1
        }else{
          mapData2["300–500k"] = 1;
        }
      }else if(item["pengeluaran_pangan_setelah"] === 5){
        if (mapData2[">500k"] !== undefined) {
          mapData2[">500k"] += 1
        }else{
          mapData2[">500k"] = 1;
        }
      }else if(item["pengeluaran_pangan_setelah"] === 6){
        if (mapData2["Tidak menjawab"] !== undefined) {
          mapData2["Tidak menjawab"] += 1
        }else{
          mapData2["Tidak menjawab"] = 1;
        }
      }
      return item
    })
    rawData.labels = Object.keys(mapData)
    rawData.datasets[0].label = "Pengeluaran pangan sebelum COVID-19*"
    rawData.datasets[1].label = "Pengeluaran pangan setelah COVID-19**"
    rawData.datasets[0].data = []
    rawData.datasets[1].data = []
    rawData.labels.map(item => {
      rawData.datasets[0].data.push(((mapData[item]/data.length)*100).toFixed(1))
      rawData.datasets[1].data.push(((mapData2[item]/data.length)*100).toFixed(1))
      return item
    })
    setGraphData(rawData)
  }, [data]);

  return <div style={{ width: '100%',height: '350px', margin:"0px auto" }}><LineChart width={1400} height={350} data={graphData} /></div>
}