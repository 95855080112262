import { useEffect, useState } from "react";
import DoughnutChart from "../../charts/DoughnutChart";
const labelFields = [
  'Lebih mudah',
  'Lebih sulit',
  'Hampir sama'
]
export default function AdaptasiCovid({ data }) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 0.8)',
            'rgba(4,135,55, 0.6)',
            'rgba(4,135,55, 0.3)',
            'rgba(4,135,55, 0.2)',
            'rgba(4,135,55, 0.1)',
          ],
          borderColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    labelFields.map(field => {
      mapData[field] = 0
      return field
    })
    data.map(item => {
      if (item.tingkat_adaptasi == 1) {
        mapData['Lebih mudah'] += 1
      }
      if (item.tingkat_adaptasi == 2) {
        mapData['Lebih sulit'] += 1
      }
      if (item.tingkat_adaptasi == 3) {
        mapData['Hampir sama'] += 1
      }
      return item
    })
    rawData.labels = Object.keys(mapData)
    rawData.datasets[0].data = []
    rawData.labels.map(item => {
      rawData.datasets[0].data.push(((mapData[item]/data.length)*100).toFixed(1))
      return item
    })
    setGraphData(rawData)
  }, [data]);

  return (
    <DoughnutChart
      data={graphData}
      legend={true}
    />
  )
}