import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TreemapController,
  TreemapElement
);

const options = {
  plugins: {
    title: {
      display: false
    },
    legend: {
      display: false,
    },
    tooltip: {
      displayColors: false,
      callbacks: {
        title(items) {
          return items[0].raw._data.name;
        },
        label(item) {
          const {
            _data: { percentage },
          } = item.raw;
          return [
            `${percentage}`,
          ];
        },
      },
    },
    datalabels :{
      display: false
    }
  },
};

export default function TreemapChart({ data, ...props }) {
  return <Chart type="treemap" data={data} options={options} { ...props } />;
}
