import { Chart as ChartJS, ArcElement, Tooltip, Legend, RadialLinearScale } from 'chart.js';
import { PolarArea } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend,ChartDataLabels, RadialLinearScale);

export default function PolarChart({ data, ...props }) {
  return <PolarArea options={{ plugins: {
    legend: {
      display: true,
      labels: {
        font: {
          size: 14
        }
      },
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          return context.formattedValue + '%'
        }
      }
    },
    datalabels :{
      display: false
    }
  } }} data={data} { ...props } />;
}
