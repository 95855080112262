import { useEffect, useState } from "react";

export default function PerubahanPekerja({ data, field, fields = [], trueOrFalse = false, useTotalPembagi = false }) {
  const [percentage, setPercentage] = useState(0)

  useEffect(() => {
    let totalBerubah = 0
    data.map(item => {
      if(item[field] > 0){
      totalBerubah += 1;
      }
      return item
    })
    console.log("totalBerubah",totalBerubah)
    let finalPembagi = data.length
    setPercentage(((totalBerubah/finalPembagi)*100).toFixed(1))
  }, [data, field, fields, trueOrFalse, useTotalPembagi]);

  return (
    <div>
      <div><span className="text-[#048737] font-extrabold text-[48px]">{percentage}</span><span className="font-bold">%</span></div>
      <div><span className="text-[#ffffff] text-[12px]">-</span></div>
    </div>
  )
}