import { useEffect, useState } from "react";
import BarChart from "../charts/BarChart";

const labelFields = {
  'Penyedia sarana': 'peran_rantai_pasok_sebelum_penyediasarana',
  'Petani, peternak,nelayan, dll': 'peran_rantai_pasok_sebelum_petanidll',
  'Distributor': 'peran_rantai_pasok_sebelum_distributor',
  'Pengolah pangan': 'peran_rantai_pasok_sebelum_pengolah',
  'Pemilik/pekerja angkutan': 'peran_rantai_pasok_sebelum_pemilik/pekerja_angkutan',
  'Pemilik/pekerja kios besar': 'peran_rantai_pasok_sebelum_pemilik/pekerja_kios_pasar_besar',
  'Pemilik/pekerja supermarket': 'peran_rantai_pasok_sebelum_pemilik/pekerja_supermarket',
  'Pemilik/pekerja kios kecil': 'peran_rantai_pasok_sebelum_pemilik/pekerja_kios_pasar_kecil',
  'Pemilik/pekerja kios pinggir jalan': 'peran_rantai_pasok_sebelum_pemilik/pekerja_kios_pinggirjalan',
  'Pemilik/pekerja kios online': 'peran_rantai_pasok_sebelum_pemilik/pekerja_kios_online',
  'Pemilik/pekerja restoran': 'peran_rantai_pasok_sebelum_pemilik/pekerja_restoran',
  'Pedagang keliling': 'peran_rantai_pasok_sebelum_pedagang keliling',
  'Eksportir': 'peran_rantai_pasok_sebelum_eksportir',
  'Importir': 'peran_rantai_pasok_sebelum_importir',
  'Lainnya': 'peran_rantai_pasok_sebelum_lainnya',
}
const labelsWithLineBreaks = Object.keys(labelFields);
export default function Peran({ data, unfilteredData }) {
  const [graphData, setGraphData] = useState({
    labels: labelsWithLineBreaks,
    datasets: []
  })

  useEffect(() => {
    const rawData = {
      labels: labelsWithLineBreaks,
      datasets: [
        {
          label: '',
          data: [],
          backgroundColor: [
            '#048737',
            '#048737',
            '#048737',
            '#048737',
            '#048737',
            '#048737',
          ],
        },
      ]
    }
    const fields = Object.values(labelFields)
    const fieldsObj = {}
    fields.map(field => {
      fieldsObj[field] = 0
      return field
    })
    data.map(item => {
      fields.map(field => {
        if (item[field] !== undefined) {
          fieldsObj[field] += item[field]
        }
        return field
      })
      return item
    })
    fields.map(field => {
      rawData.datasets[0].data.push(((fieldsObj[field]/data.length) * 100).toFixed(1))
      return field
    })
    const sortedData = {
      ...rawData,
      labels: [...rawData.labels].sort((a, b) => rawData.datasets[0].data[rawData.labels.indexOf(b)] - rawData.datasets[0].data[rawData.labels.indexOf(a)]),
      datasets: rawData.datasets.map(dataset => ({
        ...dataset,
        data: [...dataset.data].sort((a, b) => b - a), // Sort data in descending order
      })),
    };
    setGraphData(sortedData)
  }, [data]);
  
  return (
    <BarChart
      isHorizontal={true}
      isXaxis={false} 
      data={graphData}
    />
  )
}