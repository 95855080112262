import { useEffect, useState } from "react"
import BarChart from "../../charts/BarChart";

const keys = {
  'Uang tunai': ["bentuk_bantuan_dana/uang tunai"],
  'Pelatihan': ["bentuk_bantuan_teknis/pelatihan"],
  'Fasilitas': ["bentuk_bantuan_fasilitasi"],
  'Pemasaran': ["bentuk_bantuan_pemasaran"],
  'Lainnya': ["bentuk_bantuan_lainnya"],
}

export default function BentukBantuan({data}) {
  const [bentukBantuan, setBentukBantuan] = useState({})
  useEffect(() => {
    const fields = Object.keys(keys)
    const rawData = []
    const mapData = {}
    data.map(item => {
      fields.map(field => {
        let value = 0
        keys[field].map(col => {
          if (item[col] !== undefined) {
            value += item[col] ? 1: 0
          }
          return col
        })
        if (mapData[field] !== undefined) {
          mapData[field] += value
          //mapData[field].percentage = Math.round(mapData[field].value/data.length * 100) + '%'
        } else {
          mapData[field] = 0
        }
        return field
      })
      return item
    })
    Object.keys(mapData).map(key => {
      rawData.push(((mapData[key]/data.filter(item => item["bentuk_bantuan_fasilitasi"] !== undefined).length) * 100).toFixed(1))
      return key
    })
    setBentukBantuan(rawData)
  }, [data]);

  return (
    <>
      <BarChart
        height={200}
        isHorizontal={true}
        isXaxis={false}
        data={{
          labels: Object.keys(keys),
          datasets: [
            {
              label: '',
              data: bentukBantuan,
              backgroundColor: [
                '#048737',
                '#048737',
                '#048737',
                '#048737',
                '#048737',
                '#048737',
              ],
            },
          ]
        }}
      />
    </>
  )
}
