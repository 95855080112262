import { useEffect, useState } from "react";
import BarChart from "../charts/BarChart";

// const keys = {
//   'Penjualan': 'kesulitan_selama_covid19_penjualan_sulit',
//   'Harga Turun': 'kesulitan_selama_covid19_harga_jual_menurun',
//   'Harga Bahan': 'kesulitan_selama_covid19_pasokan/harga_bahan_baku',
//   'Penyimpanan': "kesulitan_selama_covid19_penyimpanan",
//   'Pengangkutan': "kesulitan_selama_covid19_transportasi/distribusi",
//   'Pembeli': "kesulitan_selama_covid19_mendapatkan_pembeli",
//   'Modal': "kesulitan_selama_covid19_mendapatkan_modal",
//   'Pemasaran': "kesulitan_selama_covid19_pemasaran_produk",
//   'Banyak Sisa': "kesulitan_selama_covid19_sisa_penjualan_banyak",
//   'Lainnya': "kesulitan_selama_covid19_mengadakan_pekerja",
// }
const keys = {
  'Penjualan': 'kesulitan_selama_covid19_penjualan_sulit',
  'Pembeli': "kesulitan_selama_covid19_mendapatkan_pembeli",
  'Modal': "kesulitan_selama_covid19_mendapatkan_modal",
  'Distribusi': "kesulitan_selama_covid19_transportasi/distribusi",
  'Harga Jual': "kesulitan_selama_covid19_harga_jual_menurun",
  'Penyimpanan': "kesulitan_selama_covid19_penyimpanan",
  'Harga Bahan**': ["kesulitan_selama_covid19_pasokan/harga_bahan_baku","kesulitan_selama_covid19_pasokan/harga_bahan _pendukung"],
  'Banyak Sisa': "kesulitan_selama_covid19_sisa_penjualan_banyak",
  'Pemasaran': "kesulitan_selama_covid19_pemasaran_produk",
  'Lainnya***': ["kesulitan_selama_covid19_mengadakan_pekerja","kesulitan_selama_covid19_lainnya"]
}
export default function Kesulitan({ data, unfilteredData }) {
  const [usaha, setUsaha] = useState({})

  useEffect(() => {
    // const fields = Object.values(keys)
    // const fieldsObj = {}
    // fields.map(field => {
    //   fieldsObj[field] = 0
    //   return field
    // })
    // data.map(item => {
    //   fields.map(field => {
    //     if (item[field] !== undefined && item[field] === true) {
    //       fieldsObj[field] += 1
    //     }
    //     return field
    //   })
    //   return item
    // })
    let rawData = []
    // fields.map(field => {
    //   rawData.push(((fieldsObj[field]/data.filter(field => field['kesulitan_selama_covid19_penjualan_sulit'] !== undefined).length) * 100).toFixed(1))
    //   return field
    // })
    const keys = {
      'Penjualan': ((data.filter(item => item['kesulitan_selama_covid19_penjualan_sulit'] === true).length / data.filter(field => field['kesulitan_selama_covid19_penjualan_sulit'] !== undefined).length)*100).toFixed(1),
      'Pembeli': ((data.filter(item => item['kesulitan_selama_covid19_mendapatkan_pembeli'] === true).length / data.filter(field => field["kesulitan_selama_covid19_penjualan_sulit"] !== undefined).length)*100).toFixed(1),
      'Modal': ((data.filter(item => item['kesulitan_selama_covid19_mendapatkan_modal'] === true).length / data.filter(field => field['kesulitan_selama_covid19_penjualan_sulit'] !== undefined).length)*100).toFixed(1),
      'Distribusi': ((data.filter(item => item['kesulitan_selama_covid19_transportasi/distribusi'] === true).length / data.filter(field => field['kesulitan_selama_covid19_penjualan_sulit'] !== undefined).length)*100).toFixed(1),
      'Harga Jual': ((data.filter(item => item['kesulitan_selama_covid19_harga_jual_menurun'] === true).length / data.filter(field => field['kesulitan_selama_covid19_penjualan_sulit'] !== undefined).length)*100).toFixed(1),
      'Penyimpanan': ((data.filter(item => item['kesulitan_selama_covid19_penyimpanan'] === true).length / data.filter(field => field['kesulitan_selama_covid19_penjualan_sulit'] !== undefined).length)*100).toFixed(1),
      'Harga Bahan**': (((data.filter(item => item['kesulitan_selama_covid19_pasokan/harga_bahan_baku'] === true ).length / data.filter(field => field['kesulitan_selama_covid19_penjualan_sulit'] !== undefined).length)*100 ) + ((data.filter(item => item['kesulitan_selama_covid19_pasokan/harga_bahan _pendukung'] === true ).length / data.filter(field => field['kesulitan_selama_covid19_penjualan_sulit'] !== undefined).length)*100 )).toFixed(1),
      'Banyak Sisa': ((data.filter(item => item['kesulitan_selama_covid19_sisa_penjualan_banyak'] === true).length / data.filter(field => field['kesulitan_selama_covid19_penjualan_sulit'] !== undefined).length)*100).toFixed(1),
      'Pemasaran': ((data.filter(item => item['kesulitan_selama_covid19_pemasaran_produk'] === true).length / data.filter(field => field['kesulitan_selama_covid19_penjualan_sulit'] !== undefined).length)*100).toFixed(1),
      'Lainnya**': ((data.filter(item => item['kesulitan_selama_covid19_mengadakan_pekerja'] === true || item['kesulitan_selama_covid19_lainnya'] === true).length / data.filter(field => field['kesulitan_selama_covid19_penjualan_sulit'] !== undefined).length)*100).toFixed(1)
    }
    //17
    console.log(Object.keys(keys))
    rawData = Object.values(keys)
    setUsaha(rawData)
  }, [data, unfilteredData]);

  return (
    <div style={{  height: '350px'}}>
    <BarChart
      isHorizontal={true}
      height={400}
      isXaxis={false}
      data={{
        labels: Object.keys(keys),
        datasets: [
          {
            label: '',
            data: usaha,
            backgroundColor: [
              '#048737',
              '#048737',
              '#048737',
              '#048737',
              '#048737',
              '#048737',
            ],
          },
        ]
      }}
    /></div>
  )
}