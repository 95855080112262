import { useEffect, useState } from "react";
import BarStackChart from "../../charts/BarStackChart";

const labelFields = [
  'Berburu hewan hutan',
  'Mengambil tanaman pangan',
  'Memancing',
  'Berburu sekitar rumah',
  'Bertani',
]
export default function KegiatanEkonomiPintar({ data }) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const mapData2 = {}
    const mapData3 = {}
    const mapData4 = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(4,135,55,1)',
          ],
          borderColor: [
            'rgba(4,135,55,1)',
          ],
          borderWidth: 1,
        },
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(92,114,74,1)',
          ],
          borderColor: [
            'rgba(92,114,74,1)',
          ],
          borderWidth: 1,
        },
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(163,182,138,1)',
          ],
          borderColor: [
            'rgba(163,182,138,1)',
          ],
          borderWidth: 1,
        },
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(199,183,147,1)',
          ],
          borderColor: [
            'rgba(199,183,147,1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    labelFields.map(field => {
      mapData[field] = 0
      mapData2[field] = 0
      mapData3[field] = 0
      mapData4[field] = 0
      return field
    })
    let lengthA = 0;
    let lengthB = 0;
    let lengthC = 0;
    let lengthD= 0;
    let lengthE= 0;
    data.map(item => {
      if([1,2,3,4].indexOf(item["frekuensi_berburu_hewanhutan"]) !== -1){
        lengthA +=1
      }
      if([1,2,3,4].indexOf(item["frekuensi_panganhutan"]) !== -1){
        lengthB+=1
      }
      if([1,2,3,4].indexOf(item["frekuensi_memancing"]) !== -1){
        lengthC+=1
      }
      if([1,2,3,4].indexOf(item["frekuensi_berburu_hewanrumah"]) !== -1){
        lengthD+=1
      }
      if([1,2,3,4].indexOf(item["frekuensi_bertani"]) !== -1){
        lengthE+=1
      }
      if(item["frekuensi_berburu_hewanhutan"] === 1){
        mapData["Berburu hewan hutan"] += 1
      }
      if(item["frekuensi_panganhutan"]===1){
        console.log("ID", item["id_urut"])
        mapData["Mengambil tanaman pangan"] += 1
      }
      if(item["frekuensi_memancing"] === 1){
        mapData["Memancing"] += 1
      }
      if(item["frekuensi_berburu_hewanrumah"] === 1){
        mapData["Berburu sekitar rumah"] += 1
      }
      if(item["frekuensi_bertani"] === 1){
        mapData["Bertani"] += 1
      }

      if(item["frekuensi_berburu_hewanhutan"] === 2){
        mapData2["Berburu hewan hutan"] += 1
      }
      if(item["frekuensi_panganhutan"] === 2){
        mapData2["Mengambil tanaman pangan"] += 1
      }
      if(item["frekuensi_memancing"] === 2){
        mapData2["Memancing"] += 1
      }
      if(item["frekuensi_berburu_hewanrumah"] === 2){
        mapData2["Berburu sekitar rumah"] += 1
      }
      if(item["frekuensi_bertani"] === 2){
        mapData2["Bertani"] += 1
      }

      if(item["frekuensi_berburu_hewanhutan"] === 3){
        mapData3["Berburu hewan hutan"] += 1
      }
      if(item["frekuensi_panganhutan"] === 3){
        mapData3["Mengambil tanaman pangan"] += 1
      }
      if(item["frekuensi_memancing"] === 3){
        mapData3["Memancing"] += 1
      }
       if(item["frekuensi_berburu_hewanrumah"] === 3){
        mapData3["Berburu sekitar rumah"] += 1
      }
      if(item["frekuensi_bertani"] === 3){
        mapData3["Bertani"] += 1
      }

      if(item["frekuensi_berburu_hewanhutan"] === 4){
        mapData4["Berburu hewan hutan"] += 1
      }
      if(item["frekuensi_panganhutan"] === 4){
        mapData4["Mengambil tanaman pangan"] += 1
      }
       if(item["frekuensi_memancing"] === 4){
        mapData4["Memancing"] += 1
      }
      if(item["frekuensi_berburu_hewanrumah"] === 4){
        mapData4["Berburu sekitar rumah"] += 1
      }
      if(item["frekuensi_bertani"] === 4){
        mapData4["Bertani"] += 1
      }
      return item
    })
    rawData.labels = Object.keys(mapData)
    rawData.datasets[0].data = []
    rawData.datasets[1].data = []
    rawData.datasets[2].data = []
    rawData.datasets[3].data = []
    rawData.labels.map(item => {
      let length = 0;
      if(item==='Berburu hewan hutan'){
        length = lengthA;
      }
      if(item==='Mengambil tanaman pangan'){
        length = lengthB;
      }
      if(item==='Memancing'){
        length = lengthC;
      }
      if(item==='Berburu sekitar rumah'){
        length = lengthD;
      }
      if(item==='Bertani'){
        length = lengthE;
      }
      rawData.datasets[0].label = 'Sama'
      rawData.datasets[1].label = 'Berkurang'
      rawData.datasets[2].label = 'Bertambah'
      rawData.datasets[3].label = 'Tidak Pernah'
      rawData.datasets[0].data.push(((mapData[item]/length)*100).toFixed(1))
      rawData.datasets[1].data.push(((mapData2[item]/length)*100).toFixed(1))
      rawData.datasets[2].data.push(((mapData3[item]/length)*100).toFixed(1))
      rawData.datasets[3].data.push(((mapData4[item]/length)*100).toFixed(1))
      return item
    })
    
    setGraphData(rawData)
  }, [data]);

  return (
    <BarStackChart
      height={350}
      isXaxis={false}
      data={graphData}
    />
  )
}