import { useEffect, useState } from "react";
import BarChart from "../../charts/BarChart";
const labelFields = [
  'TV',
  'Radio',
  'Poster/ selebaran',
  'Internet',
  'Lainnya',
  'Lupa/ tidak jawab'
]
export default function FrekuensiMedia({ data }) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 0.8)',
            'rgba(4,135,55, 0.6)',
            'rgba(4,135,55, 0.3)',
            'rgba(4,135,55, 0.2)',
            'rgba(4,135,55, 0.1)',
          ],
          borderColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    labelFields.map(field => {
      mapData[field] = 0
      return field
    })
    data.map(item => {
      if(item["pengaruh_tv"] === 1){
        mapData["TV"] += 1
      }
      if(item["pengaruh_radio"] === 1){
        mapData["Radio"] += 1
      }
      if(item["pengaruh_selebaran"] === 1){
        mapData["Poster/ selebaran"] += 1
      }
      if(item["pengaruh_internet"] === 1){
        mapData["Internet"] += 1
      }
      if(item["pengaruh_lainnya"] === 1){
        mapData["Lainnya"] += 1
      }
      if(item["pengaruh_lupa"] === 1){
        mapData["Lupa/ tidak jawab"] += 1
      }
      return item
    })
    rawData.labels = Object.keys(mapData)
    rawData.datasets[0].data = []
    rawData.labels.map(item => {
      rawData.datasets[0].data.push(((mapData[item]/data.length)*100).toFixed(1))
      return item
    })
    setGraphData(rawData)
  }, [data]);

  return (
    <BarChart
      height={250}
      isXaxis={false}
      isHorizontal={true}
      data={graphData}
    />
  )
}