import { useEffect, useState } from "react"
import BarChart from "../../charts/BarChart";

const keys = {
  'Hama alami': ["manfaat_hutan_perlindungan_hama_alami"],
  'Ketersediaan hewan buruan': ["manfaat_hutan_ketersediaan_hewan_buruan"],
  'Tanaman pangan dan kesehatan': ["manfaat_hutan_ketersediaan_tanaman_pangan_dan_kesehatan"],
  'Pupuk alami': ["manfaat_hutan_ketersediaan_tanaman/hewan_untuk_pestisida_alami"],
  'Lainnya': ["manfaat_hutan_lainnya"],
}

const labelsWithLineBreaks = Object.keys(keys).map(label => label.split(' '));
export default function ManfaatHutan2({data}) {
  const [ManfaatHutan2, setManfaatHutan2] = useState({})
  useEffect(() => {
    const fields = Object.keys(keys)
    const rawData = []
    const mapData = {}
    fields.map(field => {
      mapData[field] = data.filter(item => item[keys[field][0]] === true).length
    })
    Object.keys(mapData).map(key => {
      rawData.push(((mapData[key]/data.filter(item => item["manfaat_hutan_perlindungan_hama_alami"] !== undefined).length) * 100).toFixed(1))
      return key
    })
    setManfaatHutan2(rawData)
  }, [data]);

  return (
    <>
      <BarChart
        height={200}
        isHorizontal={true}
        isXaxis={false}
        data={{
          labels: labelsWithLineBreaks,
          datasets: [
            {
              label: '',
              data: ManfaatHutan2,
              backgroundColor: [
                '#048737',
                '#048737',
                '#048737',
                '#048737',
                '#048737',
                '#048737',
              ],
            },
          ]
        }}
      />
    </>
  )
}
