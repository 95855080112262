import { useEffect, useState } from "react"
import PolarChart from "../../charts/PolarChart";

const labelBentukBantuan = {
  'Penampung individu': 'target_konsumen_tengkulak_individu',
  'Penampung perusahaan': 'target_konsumen_tengkulak_perusahaan',
  'Penampung institusi/NGO': 'target_konsumen_institusi',
  'Distributor': 'target_konsumen_distributor',
  'Koperasi': 'target_konsumen_koperasi',
  'Pondok penjualan': 'target_konsumen_pondok_penjualan_sekitar',
  'Konsumen langsung di sekitar': 'target_konsumen_konsumen_langsung_sekitar',
  'Konsumen langsung di pasar': 'target_konsumen_konsumen_langsung_di_pasar',
  'Bulog/pemerintah': 'target_konsumen_bulog/pemerintah',
  'Lainnya': 'target_konsumen_lainnya',
  }

export default function TargetPembeli({data, unfilteredData}) {
  const [bentukBantuan, setBentukBantuan] = useState({})
  useEffect(() => {
    const fields = Object.values(labelBentukBantuan)
    const fieldsObj = {}
    fields.map(field => {
      fieldsObj[field] = 0
      return field
    })
    data.map(item => {
      fields.map(field => {
        if (item[field] !== undefined) {
          fieldsObj[field] += item[field]
        }
        return field
      })
      return item
    })
    const rawData = []
    console.log(fieldsObj)
    fields.map(field => {
      rawData.push(((fieldsObj[field]/data.filter(item => item["target_konsumen_tengkulak_individu"] !== undefined).length) * 100).toFixed(1))
      return field
    })
    setBentukBantuan(rawData)
  }, [data]);

  return (
    <>
      <PolarChart
        isHorizontal={true}
        height={300}
        data={{
          labels: Object.keys(labelBentukBantuan),
          datasets: [
            {
              label: '',
              data: bentukBantuan,
              backgroundColor: [
                'rgba(4,135,55, 1)',
                'rgba(4,135,55, 0.8)',
                'rgba(4,135,55, 0.6)',
                'rgba(4,135,55, 0.3)',
                'rgba(4,135,55, 0.2)',
                'rgba(4,135,55, 0.1)',
              ],
            },
          ]
        }}
      />
    </>
  )
}
