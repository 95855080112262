import { useEffect, useState } from "react";
import BarChart from "../charts/BarChart";

export default function PerubahanPendapatan({ data }) {
  const [pendapatan, setPendapatan] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [],
          backgroundColor: [
            '#048737',
            '#048737',
            '#048737',
            '#048737',
            '#048737',
            '#048737',
          ],
        },
      ]
    }
    data.map(item => {
      if (mapData[item.perubahan_pendapatan_banyaknya] !== undefined) {
        mapData[item.perubahan_pendapatan_banyaknya] += 1
      } else {
        mapData[item.perubahan_pendapatan_banyaknya] = 1
      }
      return item
    })
    const newMapData = {}
    const newArraySort = [
      'Menurun sampai 30%',
      'Menurun, 30-50%',
      'Menurun, >50%',
      'Menurun sampai 30%',
      'Meningkat sampai 30%',
      'Meningkat, 30-50%',
      'Tidak berubah',
      'Tidak menentu',
      'Tidak menjawab'
    ]
    newArraySort.map(item => {
      newMapData[item] = mapData[item]
      return item
    })
    rawData.labels = Object.keys(newMapData)
    rawData.labels.map(item => {
      rawData.datasets[0].data.push(((newMapData[item]/data.length)*100).toFixed(1))
      return item
    })

    const labelsWithLineBreaks = rawData.labels.map(label => label.replace('Menurun ','Menurun, ').replace('Meningkat ','Meningkat, ').split(' '));

    const modifiedData = {
      ...rawData,
      labels: labelsWithLineBreaks,
    };
    setPendapatan(modifiedData)
  }, [data]);

  return (
    <BarChart
      isHorizontal={false}
      isXaxis={false}
      height={100}
      data={pendapatan}
    />
  )
}