import { useEffect, useState } from "react";
import BarStackChart from "../../charts/BarStackChart";

const labelFields = [
  'Barter',
  'Masakan rumahan',
  'Makanan instan',
  'Restoran/ warung',
  'Chat/ telepon',
  'Aplikasi online',
]
const labelKey = [
  "frekuensi_barter",
  "frekuensi_makanan_rumahan",
  "frekuensi_makanan_instan",
  "frekuensi_makanan_restoran",
  "frekuensi_makanan_chat",
  "frekuensi_makanan_online"
]
export default function KegiatanMetodeMakanan({ data }) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const mapData2 = {}
    const mapData3 = {}
    const mapData4 = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(4,135,55,1)',
          ],
          borderColor: [
            'rgba(4,135,55,1)',
          ],
          borderWidth: 1,
        },
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(92,114,74,1)',
          ],
          borderColor: [
            'rgba(92,114,74,1)',
          ],
          borderWidth: 1,
        },
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(163,182,138,1)',
          ],
          borderColor: [
            'rgba(163,182,138,1)',
          ],
          borderWidth: 1,
        },
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(199,183,147,1)',
          ],
          borderColor: [
            'rgba(199,183,147,1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    labelFields.map(field => {
      mapData[field] = 0
      mapData2[field] = 0
      mapData3[field] = 0
      mapData4[field] = 0
      return field
    })
    let lengthA = 0;
    let lengthB = 0;
    let lengthC = 0;
    let lengthD= 0;
    let lengthE= 0;
    let lengthF= 0;
    data.map(item => {
      labelKey.forEach(function (field, i) {
        if([1,2,3,4].indexOf(item[field]) !== -1){
          if(i===0){
            lengthA +=1
          }
          if(i===1){
            lengthB +=1
          }
          if(i===2){
            lengthC +=1
          }
          if(i===3){
            lengthD +=1
          }
          if(i===4){
            lengthE +=1
          }
          if(i===5){
            lengthF +=1
          }
        }

        if(item[field] === 1){
          mapData[labelFields[i]] += 1
        }
        if(item[field] === 2){
          mapData2[labelFields[i]] += 1
        }
        if(item[field] === 3){
          mapData3[labelFields[i]] += 1
        }
        if(item[field] === 4){
          mapData4[labelFields[i]] += 1
        }
      })
      return item
    })
    rawData.labels = Object.keys(mapData)
    rawData.datasets[0].data = []
    rawData.datasets[1].data = []
    rawData.datasets[2].data = []
    rawData.datasets[3].data = []
    rawData.labels.forEach(function (item, i) {
      let length = 0;
      if(i===0){
        length = lengthA;
      }
      if(i===1){
        length = lengthB;
      }
      if(i===2){
        length = lengthC;
      }
      if(i===3){
        length = lengthD;
      }
      if(i===4){
        length = lengthE;
      }
      if(i===5){
        length = lengthF;
      }
      rawData.datasets[0].label = 'Sama'
      rawData.datasets[1].label = 'Berkurang'
      rawData.datasets[2].label = 'Bertambah'
      rawData.datasets[3].label = 'Tidak Pernah'
      rawData.datasets[0].data.push(((mapData[item]/length)*100).toFixed(1))
      rawData.datasets[1].data.push(((mapData2[item]/length)*100).toFixed(1))
      rawData.datasets[2].data.push(((mapData3[item]/length)*100).toFixed(1))
      rawData.datasets[3].data.push(((mapData4[item]/length)*100).toFixed(1))
      return item
    })
    setGraphData(rawData)
  }, [data]);

  return (
    <BarStackChart
      height={350}
      isXaxis={false}
      data={graphData}
    />
  )
}