import { useEffect, useState } from "react";
import PieChart from "../charts/PieChart";

const labelPendidikanTerakhir = {
  'kesulitan': 0,
  'tidak kesulitan': 0,
  'tidak berdampak': 0,
  'tidak menjawab': 0,
  'untung & rugi': 0
}
const labelsWithLineBreaks = Object.keys(labelPendidikanTerakhir).map(label => label.charAt(0).toUpperCase() + label.slice(1));
export default function KategoriDampakUsaha({ data }) {
  const [pendidikanTerakhir, setPendidikanTerakhir] = useState(labelPendidikanTerakhir)
  const [pendidikanTerakhirPercentage, setPendidikanTerakhirPercentage] = useState(labelPendidikanTerakhir)

  useEffect(() => {
    /**
     * pendidikan terakhir
     */
    const fLabelPendidikanTerakhir = { ...labelPendidikanTerakhir }
    data.map(item => {
      if (fLabelPendidikanTerakhir[item['kategori dampak usaha']] !== undefined) {
        fLabelPendidikanTerakhir[item['kategori dampak usaha']] += 1
      }
      return item
    })
    const rawPercentagePendidikanTerakhir = []
    Object.keys(fLabelPendidikanTerakhir).map(item => {
      rawPercentagePendidikanTerakhir.push(((fLabelPendidikanTerakhir[item.toLowerCase()]/data.length)*100).toFixed(1))
      return item
    })
    setPendidikanTerakhirPercentage(rawPercentagePendidikanTerakhir)
    setPendidikanTerakhir(fLabelPendidikanTerakhir)
  }, [data]);

  return (
    <PieChart
      isHorizontal={false}
      height={400}
      isXaxis={false}
      data={{
        labels: labelsWithLineBreaks,
        datasets: [
          {
            label: '',
            data: pendidikanTerakhirPercentage,
            backgroundColor: [
              'rgba(4,135,55, 1)',
            'rgba(4,135,55, 0.8)',
            'rgba(4,135,55, 0.6)',
            'rgba(4,135,55, 0.3)',
            'rgba(4,135,55, 0.2)',
            'rgba(4,135,55, 0.1)',
            ],
          },
        ]
      }}
    />
  )
}