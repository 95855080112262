import { useEffect, useState } from "react";
import BubbleChart from "../../charts/BubbleChart";
const keys = {
  'Dapat bantuan': ["adaptasi_bantuan"],
  'Pinjam dan atau minta makan/ uang': ["adaptasi_meminta","adaptasi_meminjam"],
  'Merubah menu': ["adaptasi_merubah_menu"],
  'Kurangi biaya makan': ["adaptasi_mengurangi_biaya_makan"],
  'Kurangi biaya nonmakan': ["adaptasi_mengurangi_biaya_nonmakan"],
  'Gunakan tabungan dan atau jual/ gadai barang': ["adaptasi_menjual","adaptasi_tabungan"],
  'Hasilkan uang lebih': ["adaptasi_menghasilkan_uang"],
}
export default function AdaptasiKeluarga1({ data }) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 0.8)',
            'rgba(4,135,55, 0.6)',
            'rgba(4,135,55, 0.3)',
            'rgba(4,135,55, 0.2)',
            'rgba(4,135,55, 0.1)',
          ],
          borderColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    const keys = {
      'Dapat bantuan': ((data.filter(item => Number.isInteger(item["adaptasi_bantuan"]) && item["adaptasi_bantuan"] == 1).length/data.filter(item => Number.isInteger(item["adaptasi_bantuan"])).length) * 100),
      'Pinjam dan atau minta makan/ uang': ((data.filter(item => Number.isInteger(item["adaptasi_meminta"]) && item["adaptasi_meminta"] == 1).length/data.filter(item => Number.isInteger(item["adaptasi_meminta"])).length) * 100) + ((data.filter(item =>  Number.isInteger(item["adaptasi_meminjam"]) && item["adaptasi_meminjam"] == 1).length/data.filter(item => Number.isInteger(item["adaptasi_meminjam"])).length) * 100),
      'Merubah menu': ((data.filter(item => Number.isInteger(item["adaptasi_merubah_menu"]) && item["adaptasi_merubah_menu"] == 1).length/data.filter(item => Number.isInteger(item["adaptasi_bantuan"])).length) * 100),
      'Kurangi biaya makan': ((data.filter(item => Number.isInteger(item["adaptasi_mengurangi_biaya_makan"]) && item["adaptasi_mengurangi_biaya_makan"] == 1).length/data.filter(item => Number.isInteger(item["adaptasi_bantuan"])).length) * 100),
      'Kurangi biaya nonmakan': ((data.filter(item => Number.isInteger(item["adaptasi_mengurangi_biaya_nonmakan"]) && item["adaptasi_mengurangi_biaya_nonmakan"] == 1).length/data.filter(item => Number.isInteger(item["adaptasi_bantuan"])).length) * 100),
      'Gunakan tabungan dan atau jual/ gadai barang':  ((data.filter(item => Number.isInteger(item["adaptasi_menjual"]) && item["adaptasi_menjual"] == 1).length/data.filter(item => Number.isInteger(item["adaptasi_menjual"])).length) * 100) + ((data.filter(item =>  Number.isInteger(item["adaptasi_tabungan"]) && item["adaptasi_tabungan"] == 1).length/data.filter(item => Number.isInteger(item["adaptasi_tabungan"])).length) * 100),
      'Hasilkan uang lebih': ((data.filter(item => Number.isInteger(item["adaptasi_menghasilkan_uang"]) && item["adaptasi_menghasilkan_uang"] == 1).length/data.filter(item => Number.isInteger(item["adaptasi_bantuan"])).length) * 100),
    }
    rawData.labels = Object.keys(keys)
    rawData.datasets[0].data = []
    rawData.datasets[0].label = "hallo"
    Object.keys(keys).map(key => {
      rawData.datasets[0].data.push( {
        x: key,
        y: (keys[key]),
        r: (keys[key]) // radius of the bubble
      })
      return key
    })
    setGraphData(rawData)
  }, [data]);

  return (
    <BubbleChart
      height={100}
      data={graphData}
      options={{
        maintainAspectRatio: true,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                console.log("VAL",context)
                return context.parsed.y.toFixed(1) + '%'
              }
            }
          },
          datalabels: {
            align: 'end', // Position the labels at the end of the bars
            anchor: 'end', // Anchor the labels at the end of the bars
            formatter: (value) => {
              return `${value.r.toFixed(1)}%`; // Format the labels as percentages
            },
            color: '#222',
            font: {
              size: 10, // Set the font size here
            }
          },
        },
        // barThickness: 8,
        // barPercentage: 0.4,
        scales: {
          x: {
            title: {
              display: true,
            },
            labels: Object.keys(keys),
            type: 'category',
          }, // Set the y-axis min and max// Set the y-axis min and max
          y: {
            ticks: {
              display: false,
              font: {
                size: 13
              }
            }
          }
        },
        layout: {
          margin: 0,
          padding: {
            top: 30,
            right: 30,
          }
        }
      }}
      category={Object.keys(keys)}
    />
  )
}