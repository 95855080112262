import { useEffect, useState } from "react";
import BarChart from "../../charts/BarChart";

const labelFields = {
  'Jalan kaki' : 'transportasi_lokasi_produksi_jalan_kaki',
  'Mobil, bukan milik pribadi' : 'transportasi_lokasi_produksi_mobil_bukan_pribadi',
  'Mobil milik sendiri' : 'transportasi_lokasi_produksi_mobil_pribadi',
  'Motor, bukan milik pribadi' : 'transportasi_lokasi_produksi_motor_bukan_pribadi',
  'Motor milik sendiri' : 'transportasi_lokasi_produksi_motor_pribadi',
  'Sepeda, bukan milik pribadi' : 'transportasi_lokasi_produksi_sepeda_bukan_pribadi',
  'Sepeda, milik sendiri' : 'transportasi_lokasi_produksi_sepeda_pribadi',
  'Lainnya, (Perahu)' : 'transportasi_lokasi_produksi_lainnya_(perahu)',
}

const labelsWithLineBreaks = Object.keys(labelFields).map(label => label.split(' '));
export default function TransportasiKeLokasiProduksi({ data }) {
  const [graphData, setGraphData] = useState({
    labels: labelsWithLineBreaks,
    datasets: []
  })

  useEffect(() => {
    const rawData = {
      labels: labelsWithLineBreaks,
      datasets: [
        {
          label: '',
          data: [],
          backgroundColor: [
            '#048737',
            '#048737',
            '#048737',
            '#048737',
            '#048737',
            '#048737',
          ],
        },
      ]
    }
    const fields = Object.values(labelFields)
    const fieldsObj = {}
    fields.map(field => {
      fieldsObj[field] = 0
      return field
    })
    data.map(item => {
      fields.map(field => {
        if (item[field] !== undefined) {
          fieldsObj[field] += item[field]
        }
        return field
      })
      return item
    })
    fields.map(field => {
      rawData.datasets[0].data.push(((fieldsObj[field]/data.length) * 100).toFixed(1))
      return field
    })
    const sortedData = {
      ...rawData,
      labels: [...rawData.labels].sort((a, b) => rawData.datasets[0].data[rawData.labels.indexOf(b)] - rawData.datasets[0].data[rawData.labels.indexOf(a)]),
      datasets: rawData.datasets.map(dataset => ({
        ...dataset,
        data: [...dataset.data].sort((a, b) => b - a), // Sort data in descending order
      })),
    };
    setGraphData(sortedData)
  }, [data]);

  return (
    <div style={{  height: '650px', margin:"10px auto" }}>
    <BarChart
      isHorizontal={true}
      height={600}
      isXaxis={false} 
      data={graphData}
    /></div>
  )
}