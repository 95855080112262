import { useEffect, useState } from "react";
import BarChart from "../charts/BarChart";

const labelFields = {
  'Makanan pokok': 'produk_usaha_sebelum_pokok',
  'Sayuran': 'produk_usaha_sebelum_sayur',
  'Buah-buahan': 'produk_usaha_sebelum_buah',
  'Perikanan': 'produk_usaha_sebelum_perikanan',
  'Peternakan': 'produk_usaha_sebelum_peternakan',
  'Hasil buruan': 'produk_usaha_sebelum_buruan',
  'Kacang-kacangan': 'produk_usaha_sebelum_kacang',
  'Hasil perkebunan': 'produk_usaha_sebelum_perkebunan',
  'Olahan segar': 'produk_usaha_sebelum_olahan_segar',
  'Olahan pabrik': 'produk_usaha_sebelum_olahan_pabrik',
  'Pendukung': 'produk_usaha_sebelum_pendukung',
  'Jasa/produk lain': 'produk_usaha_sebelum_jasa_produklain',
}

const labelsWithLineBreaks = Object.keys(labelFields);
export default function ProdukUsaha({ data, unfilteredData }) {
  const [graphData, setGraphData] = useState({
    labels: labelsWithLineBreaks,
    datasets: []
  })

  useEffect(() => {
    const rawData = {
      labels: labelsWithLineBreaks,
      datasets: [
        {
          label: '',
          data: [],
          backgroundColor: [
            '#048737',
            '#048737',
            '#048737',
            '#048737',
            '#048737',
            '#048737',
          ],
        },
      ]
    }
    const fields = Object.values(labelFields)
    const fieldsObj = {}
    fields.map(field => {
      fieldsObj[field] = 0
      return field
    })
    data.map(item => {
      fields.map(field => {
        if (item[field] !== undefined) {
          fieldsObj[field] += item[field]
        }
        return field
      })
      return item
    })
    fields.map(field => {
      rawData.datasets[0].data.push(((fieldsObj[field]/data.length) * 100).toFixed(1))
      return field
    })
    const sortedData = {
      ...rawData,
      labels: [...rawData.labels].sort((a, b) => rawData.datasets[0].data[rawData.labels.indexOf(b)] - rawData.datasets[0].data[rawData.labels.indexOf(a)]),
      datasets: rawData.datasets.map(dataset => ({
        ...dataset,
        data: [...dataset.data].sort((a, b) => b - a), // Sort data in descending order
      })),
    };
    setGraphData(sortedData)
  }, [data]);
  
  return (
    <BarChart
      isHorizontal={true}
      isXaxis={false} 
      data={graphData}
    />
  )
  // const [usaha, setUsaha] = useState({})

  // useEffect(() => {
  //   const fields = Object.values(keys)
  //   const fieldsObj = {}
  //   fields.map(field => {
  //     fieldsObj[field] = 0
  //     return field
  //   })
  //   data.map(item => {
  //     fields.map(field => {
  //       if (item[field] !== undefined) {
  //         fieldsObj[field] += item[field]
  //       }
  //       return field
  //     })
  //     return item
  //   })
  //   const rawData = []
  //   fields.map(field => {
  //     rawData.push(((fieldsObj[field]/unfilteredData.length) * 100).toFixed(1))
  //     return field
  //   })
  //   setUsaha(rawData)
  // }, [data, unfilteredData]);

  // return (
  //   <BarChart
  //     isHorizontal={true}
  //     isXaxis={false}
  //     data={{
  //       labels: Object.keys(keys),
  //       datasets: [
  //         {
  //           label: '',
  //           data: usaha,
  //           backgroundColor: [
  //             '#048737',
  //             '#048737',
  //             '#048737',
  //             '#048737',
  //             '#048737',
  //             '#048737',
  //           ],
  //         },
  //       ]
  //     }}
  //   />
  // )
}