import { useEffect, useState } from "react";
import BarChart from "../../charts/BarChart";
const labelFields = [
  'Dana/uang tunai',
  'Bahan pangan',
  'Masukan pertanian',
  'Lainnya'
]
export default function BentukBantuan({ data }) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 0.8)',
            'rgba(4,135,55, 0.6)',
            'rgba(4,135,55, 0.3)',
            'rgba(4,135,55, 0.2)',
            'rgba(4,135,55, 0.1)',
          ],
          borderColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    labelFields.map(field => {
      mapData[field] = 0
      return field
    })
    data.map(item => {
      if(item["bantuan_uang"] === 1){
        mapData["Dana/uang tunai"] += 1
      }
      if(item["bantuan_bahan_pangan"] === 1){
        mapData["Bahan pangan"] += 1
      }
      if(item["bantuan_masukan_pertanian"] === 1){
        mapData["Masukan pertanian"] += 1
      }
      if(item["bantuan_lainnya2"] === 1){
        mapData["Lainnya"] += 1
      }
      return item
    })
    rawData.labels = Object.keys(mapData)
    rawData.datasets[0].data = []
    rawData.labels.map(item => {
      rawData.datasets[0].data.push(((mapData[item]/data.filter(item => Number.isInteger(item["bantuan_uang"])).length)*100).toFixed(1))
      return item
    })
    const labelsWithLineBreaks = rawData.labels.map(label => label.split(' '));

    const modifiedData = {
      ...rawData,
      labels: labelsWithLineBreaks,
    };
    setGraphData(modifiedData)
  }, [data]);

  return (
    <BarChart
      height={250}
      isXaxis={false}
      data={graphData}
    />
  )
}