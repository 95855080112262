import { useEffect, useState } from "react";
import DoughnutChart from "../charts/DoughnutChart";

export default function DampakBantuan({ data }) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 0.8)',
            'rgba(4,135,55, 0.6)',
            'rgba(4,135,55, 0.3)',
            'rgba(4,135,55, 0.2)',
            'rgba(4,135,55, 0.1)',
          ],
          borderColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    data.map(item => {
      if(item["dampak_bantuan"] === 1){
        if (mapData["Sangat terbantu"] !== undefined) {
          mapData["Sangat terbantu"] += 1
        }else{
          mapData["Sangat terbantu"] = 1;
        }
      }else if(item["dampak_bantuan"] === 2){
        if (mapData["Lumayan terbantu"] !== undefined) {
          mapData["Lumayan terbantu"] += 1
        }else{
          mapData["Lumayan terbantu"] = 1;
        }
      }else if(item["dampak_bantuan"] === 3){
        if (mapData["Sedikit terbantu"] !== undefined) {
          mapData["Sedikit terbantu"] += 1
        }else{
          mapData["Sedikit terbantu"] = 1;
        }
      }else if(item["dampak_bantuan"] === 4){
        if (mapData["Tidak terbantu"] !== undefined) {
          mapData["Tidak terbantu"] += 1
        }else{
          mapData["Tidak terbantu"] = 1;
        }
      }
      return item
    })
    rawData.labels = Object.keys(mapData)
    rawData.datasets[0].data = []
    rawData.labels.map(item => {
      rawData.datasets[0].data.push(((mapData[item]/data.filter(item => Number.isInteger(item["dampak_bantuan"])).length)*100).toFixed(1))
      return item
    })
    setGraphData(rawData)
  }, [data]);

  return <DoughnutChart data={graphData} legend={true} />
}