import { Link } from 'react-router-dom';
import Carousel, { Modal, ModalGateway } from 'react-images'
import React, { useState } from 'react';
import ReactGA from "react-ga4";


export default function Depan() {

  ReactGA.send({ hitType: "pageview", page: "/depan", title: "Beranda" });

  const [currentImage, setCurrentImage] = useState(0);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);

  const partners = [
    {
      name: 'CIFOR-ICRAF',
      image: '/partners/cifor-icraf.png'
    },
    {
      name: 'Papua Barat',
      image: '/partners/papua-barat.png'
    },
    {
      name: 'USAID',
      image: '/partners/usaid.png'
    },
    {
      name: 'FCDO',
      image: '/partners/fcdo.png'
    },
    {
      name: 'Nutriscape',
      image: '/partners/nutriscape.png'
    }
  ]

  const images = [
    { src: '/foto/CIFOR/1.jpg',caption: "CIFOR"},
    { src: '/foto/CIFOR/2.jpg',caption: "CIFOR" },
    { src: '/foto/CIFOR/3.jpg',caption: "CIFOR" },
    { src: '/foto/BRIDA/1.jpeg',caption: "BRIDA Papua Barat" },
    { src: '/foto/BRIDA/2.png',caption: "BRIDA Papua Barat" },
    { src: '/foto/BRIDA/3.png',caption: "BRIDA Papua Barat" },
    { src: '/foto/BRIDA/4.jpeg',caption: "BRIDA Papua Barat" },
    { src: '/foto/BRIDA/5.jpeg',caption: "BRIDA Papua Barat" },
    { src: '/foto/BRIDA/6.jpeg',caption: "BRIDA Papua Barat" },
    { src: '/foto/BRIDA/7.jpeg',caption: "BRIDA Papua Barat" },
    { src: '/foto/BRIDA/8.jpeg',caption: "BRIDA Papua Barat" },
    { src: '/foto/BRIDA/9.jpeg',caption: "BRIDA Papua Barat" },
    { src: '/foto/BRIDA/10.jpeg',caption: "BRIDA Papua Barat" },
    { src: '/foto/BRIDA/11.jpeg',caption: "BRIDA Papua Barat" },
    { src: '/foto/BRIDA/12.jpeg',caption: "BRIDA Papua Barat" },
    { src: '/foto/BRIDA/13.jpeg',caption: "BRIDA Papua Barat" },
    { src: '/foto/BRIDA/14.jpeg',caption: "BRIDA Papua Barat" },
    { src: '/foto/BRIDA/15.jpeg',caption: "BRIDA Papua Barat" },
    // Add more images as needed
  ];

  const openLightbox = (index) => {
    setCurrentImage(index);
    setLightboxIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setLightboxIsOpen(false);
  };
  return (
    <>
      <div className='container mx-auto py-[15px] px-[15px] md:py-[53px] md:px-[76px] relative'>
        <div className='xl:flex w-full mb-[77px]'>
          <div className="w-full text-neutral-700 mb-8 xl:mb-0 text-[17px] xl:w-[40%]">
            <h2 className="text-stone-800 mb-[25px] text-[50px] leading-tight font-bold">Dampak COVID-19 terhadap Sistem Pangan di Provinsi Papua Barat</h2>
            <p className='italic text-xs	text-green-900'>Survei ini telah mendapat izin kerja lapangan dari Satgas COVID-19 setempat dan surat dukungan dari kantor Gubernur. Selama pengambilan data, pencegahan COVID-19 dilakukan sesuai protokol kesehatan yang berlaku, seperti menjaga jarak, menggunakan masker, dan memberikan hand sanitizer kepada responden.</p>
          </div>
          <div className='w-full text-neutral-700 mb-8 xl:mb-0 text-[17px] leading-[25px] xl:pl-[90px] xl:w-[50%]'>
            <p className='mb-4 font-bold'>Tahun 2020, pandemi COVID-19 telah melanda Indonesia dan berdampak pada sistem pangan di Indonesia, termasuk Provinsi Papua Barat. Ketahanan pangan menjadi prioritas utama bagi pemerintah Provinsi Papua Barat sehingga pemerintah perlu mendapatkan informasi yang valid terkait gangguan dan dampak COVID-19 ini terhadap sistem pangan.</p>
            <p className='font-light'><i>Center for International Forestry Research</i> (CIFOR), yang didanai oleh USAID <i>Project Conservation and Sustainable Use of Tropical Forest Biodiversity</i> dan <i>United Kingdom Climate Change Unit</i> (UKCCU), bermitra dengan Badan Riset dan Inovasi Daerah (BRIDA) Papua Barat, telah melakukan tiga survei terkait sistem pangan di kabupaten/kota Provinsi Papua Barat selama pandemi COVID-19 pada tahun 2020. Data survei ini dimungkinkan masih mencakup kabupaten yang saat ini merupakan bagian dari Papua Barat Daya.</p>
          </div>
        </div>
      </div>
      <div style={{backgroundColor:'#048737'}}>
        <div className='container mx-auto py-[15px] px-[15px] md:py-[53px] md:px-[76px] relative'>
          <h2 className="text-stone-800 mb-[25px] text-[50px] leading-tight text-3xl text-white	 font-bold">Hasil Survei</h2>
          <div className='md:grid grid-cols-3 gap-6 w-full mb-[77px]'>
            <div className="flex flex-col justify-between	w-full text-neutral-700 mb-8 xl:mb-0 text-[17px] py-[40px] px-[40px] rounded"  style={{backgroundColor:'#033717'}}>
              <div>
                <img src="/s1.png" alt="image" style={{height : '80px'}}/>
                <div className='border-b mt-8 mb-10'></div>
                <h3 className="text-stone-800 mb-[25px] leading-tight text-xl text-white font-bold">Survei Pelaku Rantai Pasok Pangan</h3>
                <p className='text-white font-light leading-7'>Survei ini melibatkan semua individu dalam rantai pasok pangan, dari proses produksi, distribusi, penjualan, hingga pemasaran pangan dan bahan pendukung produksi pangan. Tujuannya untuk mengidentifikasi pelaku dan produk dalam rantai pasok pangan, dampak COVID-19, dan strategi dalam menghadapi COVID-19. Hasil survei diharapkan dapat membantu perencanaan kebijakan pemerintah untuk mendukung pelaku rantai pasok pangan di Papua Barat. Dilaksanakan secara semi-online dari September hingga Desember 2020, melalui pengedaran tautan survei dan kunjungan enumerator ke berbagai tempat.</p>
              </div>
              <Link to={'/survey/pelaku-rantai-pasok-pangan'} className='border rounded block w-full	text-center	bg-transparent py-4 text-white mt-10'>LIHAT SURVEI</Link>
            </div>
            <div className="flex flex-col justify-between	w-full text-neutral-700 mb-8 xl:mb-0 text-[17px] py-[40px] px-[40px] rounded"  style={{backgroundColor:'#033717'}}>
              <div>
                <img src="/s2.png" alt="image" style={{height : '80px'}}/>
                <div className='border-b mt-8 mb-10'></div>
                <h3 className="text-stone-800 mb-[25px] leading-tight text-xl text-white font-bold">Survei Produsen Pangan</h3>
                <p className='text-white font-light leading-7'>Survei ini ditujukan kepada individu yang terlibat langsung dalam produksi pangan mentah dan segar. Tujuannya untuk memahami dampak pandemi COVID-19, strategi produsen, dan faktor-faktor peningkatan produksi dan kesejahteraan. Hasilnya diharapkan dapat membantu perencanaan kebijakan pemerintah untuk mendukung produsen pangan utama di Papua Barat. Dilakukan secara offline dari November 2020 hingga Mei 2021, dengan wawancara langsung di lokasi produksi dan kunjungan ke berbagai tempat.</p>
              </div>
              <Link to={'/survey/produsen-pangan'} className='border rounded block w-full	text-center	bg-transparent py-4 text-white mt-10'>LIHAT SURVEI</Link>
            </div>
            <div className="flex flex-col justify-between	w-full text-neutral-700 mb-8 xl:mb-0 text-[17px] py-[40px] px-[40px] rounded"  style={{backgroundColor:'#033717'}}>
              <div>
                <img src="/s3.png" alt="image" style={{height : '80px'}}/>
                <div className='border-b mt-8 mb-10'></div>
                <h3 className="text-stone-800 mb-[25px] leading-tight text-xl text-white font-bold">Survei Konsumen Pangan</h3>
                <p className='text-white font-light leading-7'>Survei ini ditujukan kepada konsumen pangan segar dan olahan di Papua Barat yang berusia di atas 18 tahun. Tujuannya untuk menganalisis perilaku konsumsi dan lingkungan pangan sebelum dan sesudah pandemi COVID-19. Hasilnya diharapkan mendukung perbaikan sistem pangan menuju pangan sehat dan berkelanjutan. Dilakukan secara online dari Februari hingga Mei 2021, dengan penyebaran tautan melalui berbagai kanal.</p>
              </div>
              <Link to={'/survey/konsumen-pangan'} className='border rounded block w-full	text-center	bg-transparent py-4 text-white mt-10'>LIHAT SURVEI</Link>
            </div>
          </div>
        </div>
      </div>
      <div style={{backgroundColor:'#F5F5F5'}}>
        <div className='container mx-auto py-[15px] px-[15px] md:py-[53px] md:px-[76px] relative'>
          <img src={images[0].src} alt="image"/>
          <div className='flex mt-4' onClick={() => openLightbox(0)} style={{cursor: "pointer"}}>
            <img src="/icons/photo.svg" alt="lihat foto"/>
            <p className='underline ml-2'>Lihat semua photo</p>
          </div>
          <ModalGateway>
            {lightboxIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel views={images} />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
      </div>
      <div style={{backgroundColor:'#ffffff'}}>
        <div className='container mx-auto py-[15px] px-[15px] md:py-[53px] md:px-[76px] relative'>
          <h2 className="text-stone-800 mb-[25px] text-[50px] leading-tight text-3xl font-bold">Tim</h2>
          <div className="">
          <table className="w-full border-collapse border-transparent">
            <tbody>
              <tr>
                <td className="px-4 py-2 pl-0 align-top" style={{width: '200px'}}>Pengarah</td>
                <td className="font-light min-w-0	w-px" style={{width: '5px'}}>:</td>
                <td className="px-4 py-2 font-light">Charlie D. Heatubun</td>
              </tr>
              <tr>
                <td className="px-4 py-2 pl-0 align-top">Peneliti utama</td>
                <td className="font-light min-w-0	w-px" style={{width: '5px'}}>:</td>
                <td className="px-4 py-2 font-light">Mulia Nurhasan, Ferry Hurulean</td>
              </tr>
              <tr>
                <td className="px-4 py-2 pl-0 align-top">Asisten peneliti</td>
                <td className="font-light min-w-0	w-px" style={{width: '5px'}}>:</td>
                <td className="px-4 py-2 font-light">Desy Leo Ariesta, Agus Muhammad Maulana, Mahdiyatur Rahmah</td>
              </tr>
              <tr>
                <td className="px-4 py-2 pl-0 align-top">Manajer proyek</td>
                <td className="font-light min-w-0	w-px" style={{width: '5px'}}>:</td>
                <td className="px-4 py-2 font-light">Heru Komarudin</td>
              </tr>
              <tr>
                <td className="px-4 py-2 pl-0 align-top">Quality control</td>
                <td className="font-light min-w-0	w-px" style={{width: '5px'}}>:</td>
                <td className="px-4 py-2 font-light">Amy Ickowitz</td>
              </tr>
              <tr>
                <td className="px-4 py-2 pl-0 align-top">Ahli rantai pasok pangan</td>
                <td className="font-light min-w-0	w-px" style={{width: '5px'}}>:</td>
                <td className="px-4 py-2 font-light">Muhammad Hariyadi Setiawan</td>
              </tr>
              <tr>
                <td className="px-4 py-2 pl-0 align-top">Koordinator Tim Lapang</td>
                <td className="font-light min-w-0	w-px" style={{width: '5px'}}>:</td>
                <td className="px-4 py-2 font-light">Ferry Hurulean</td>
              </tr>
              <tr>
                <td className="px-4 py-2 pl-0 align-top">Tim Lapang</td>
                <td className="font-light min-w-0	w-px" style={{width: '5px'}}>:</td>
                <td className="px-4 py-2 font-light">Alfredo, Jhon Gotlief Geisler, Myrdal Kabes, Ricky Sabono, Johanis Daniel Mabruaru, Robin Atariababa, Simon petrus Lefteuw, Alberth Yomo, Rian, Berto, Isak, Marlin Marani, Tiwi, Yohanis Daniel Mabruaru Abbe, Anis, Ave Heatubun, Desi, Emalia Talaubun, Ferry Hurulean, Lia, Lies Bethy Rumbiak, Ronsiwer Awom </td>
              </tr>
              <tr>
                <td className="px-4 py-2 pl-0 align-top">Layout and design</td>
                <td className="font-light min-w-0	w-px" style={{width: '5px'}}>:</td>
                <td className="px-4 py-2 font-light">Desy Leo Ariesta, Mahdiyatur Rahmah, Renta L. Damanik, Dodi Iriyanto, Faqih Amruddin Y, Christoffel S.I Maweikere, Jhonal Thio </td>
              </tr>
            </tbody>
          </table>
        </div>
        </div>
      </div>
      <div style={{backgroundColor:'#ffffff'}}>
        <div className='container mx-auto py-[15px] px-[15px] md:py-[53px] md:px-[76px] relative'>
          <div className='xl:flex w-full mb-[77px]'>
            <div className="w-full text-neutral-700 mb-8 xl:mb-0 text-[17px] xl:w-[60%]">
              <h2 className="text-stone-800 mb-[25px] text-[18px] leading-tight font-bold">Mitra</h2>
              <div className="w-full">
                {partners.map((item, key) => {
                  return (
                    <img key={key} src={item.image} alt={item.name} className="shrink-0 h-auto w-auto inline-block md:mr-10 mb-5" style={{height: '70px'}}/>
                  )
                })}
              </div>
            </div>
            <div className="w-full text-neutral-700 mb-8 xl:mb-0 text-[17px] xl:w-[40%] md:border-l-2 md:pl-8">
              <h2 className="text-stone-800 mb-[25px] text-[18px] leading-tight font-bold">Kontak</h2>
              <p className='font-light'>
BRIDA Provinsi Papua Barat <br></br>
E-mail: brida@papuabaratprov.go.id <br></br>
Web: brida.papuabaratprov.go.id</p>

<p className='font-light mt-4'>
CIFOR-ICRAF <br></br>
Email: cifor@cifor-icraf.org <br></br>
Web: www.cifor.org</p>
<p className='font-light mt-4'>
Peneliti Utama <br></br>
M.Nurhasan@cifor-icraf.org</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

