import { useEffect, useState } from "react";
import BarChart from "../../charts/BarChart";

const labelFields = [
  'Tidak sekolah',
  'SD',
  'SMP',
  'SMA',
  'Perguruan tinggi/ Universitas'
]
export default function TingkatPendidikan({ data }) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 0.8)',
            'rgba(4,135,55, 0.6)',
            'rgba(4,135,55, 0.3)',
            'rgba(4,135,55, 0.2)',
            'rgba(4,135,55, 0.1)',
          ],
          borderColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    labelFields.map(field => {
      mapData[field] = 0
      return field
    })
    data.map(item => {
      if (mapData[item.tingkat_pendidikan_terakhir.replace(" dan sederajat", "")] !== undefined) {
        mapData[item.tingkat_pendidikan_terakhir.replace(" dan sederajat", "")] += 1
      } else {
        mapData[item.tingkat_pendidikan_terakhir.replace(" dan sederajat", "")] = 1
      }
      return item
    })
    rawData.labels = Object.keys(mapData)
    rawData.datasets[0].data = []
    rawData.labels.map(item => {
      rawData.datasets[0].data.push(((mapData[item]/data.length)*100).toFixed(1))
      return item
    })

    const labelsWithLineBreaks = rawData.labels.map(label => label.split(' '));

    const modifiedData = {
      ...rawData,
      labels: labelsWithLineBreaks,
    };
    setGraphData(modifiedData)
  }, [data]);

  return (
    <BarChart
      height={125}
      isXaxis={false}
      data={graphData}
    />
  )
}