import { useEffect, useState } from "react"
import PolarChart from "../../charts/PolarChart";
import PieChart from "../../charts/PieChart";

const labelBentukBantuan = {
  'Membeli': 'input_produksi_membeli',
  'Membuat': 'input_produksi_membuat_sendiri',
  'Bantuan': 'input_produksi_bantuan',
  'Pinjam': 'input_produksi_pinjam',
  'Menyewa': 'input_produksi_menyewa',
  'Pemakaian bersama': 'input_produksi_pemakaian_bersama',
  'Lainnya': 'input_produksi_lainnya',
  }

export default function InputProduksi({data}) {
  const [bentukBantuan, setBentukBantuan] = useState({})
  useEffect(() => {
    const fields = Object.values(labelBentukBantuan)
    const fieldsObj = {}
    fields.map(field => {
      fieldsObj[field] = 0
      return field
    })
    data.map(item => {
      fields.map(field => {
        if (item[field] !== undefined) {
          fieldsObj[field] += item[field]
        }
        return field
      })
      return item
    })
    const rawData = []
    console.log(fieldsObj)
    fields.map(field => {
      rawData.push(((fieldsObj[field]/data.length) * 100).toFixed(1))
      return field
    })
    setBentukBantuan(rawData)
  }, [data]);

  return (
    <>
      <PolarChart
        height={200}
        data={{
          labels: Object.keys(labelBentukBantuan),
          datasets: [
            {
              label: '',
              data: bentukBantuan,
              backgroundColor: [
                'rgba(4,135,55, 1)',
                'rgba(4,135,55, 0.8)',
                'rgba(4,135,55, 0.6)',
                'rgba(4,135,55, 0.3)',
                'rgba(4,135,55, 0.2)',
                'rgba(4,135,55, 0.1)',
              ],
            },
          ]
        }}
      />
    </>
  )
}
