import { useEffect, useState } from "react";
import BarChart from "../../charts/BarChart";
const labelFields = [
  'Kehilangan pendapatan',
  'Pendapatan menurun',
  'Pendapatan meningkat',
  'Pendapatan tidak tentu',
  'Tidak berubah'
]
export default function PerubahanPendapatan({ data }) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 0.8)',
            'rgba(4,135,55, 0.6)',
            'rgba(4,135,55, 0.3)',
            'rgba(4,135,55, 0.2)',
            'rgba(4,135,55, 0.1)',
          ],
          borderColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    labelFields.map(field => {
      mapData[field] = 0
      return field
    })
    data.map(item => {
      if(item["perubahan_pendapatan"] === 1){
        if (mapData["Kehilangan pendapatan"] !== undefined) {
          mapData["Kehilangan pendapatan"] += 1
        } else {
          mapData["Kehilangan pendapatan"] = 1
        }
      }
      if(item["perubahan_pendapatan"] === 2 || (item["perubahan_pendapatan"] === 3)){
        if (mapData["Pendapatan menurun"] !== undefined) {
          mapData["Pendapatan menurun"] += 1
        } else {
          mapData["Pendapatan menurun"] = 1
        }
      }
      if(item["perubahan_pendapatan"] === 5){
        if (mapData["Pendapatan meningkat"] !== undefined) {
          mapData["Pendapatan meningkat"] += 1
        } else {
          mapData["Pendapatan meningkat"] = 1
        }
      }
      if(item["perubahan_pendapatan"] === 6){
        if (mapData["Pendapatan tidak tentu"] !== undefined) {
          mapData["Pendapatan tidak tentu"] += 1
        } else {
          mapData["Pendapatan tidak tentu"] = 1
        }
      }
      if(item["perubahan_pendapatan"] === 7){
        if (mapData["Tidak berubah"] !== undefined) {
          mapData["Tidak berubah"] += 1
        } else {
          mapData["Tidak berubah"] = 1
        }
      }
      return item
    })
    rawData.labels = Object.keys(mapData)
    rawData.datasets[0].data = []
    rawData.labels.map(item => {
      rawData.datasets[0].data.push(((mapData[item]/data.length)*100).toFixed(1))
      return item
    })
    const labelsWithLineBreaks = rawData.labels.map(label => label.split(' '));

    const modifiedData = {
      ...rawData,
      labels: labelsWithLineBreaks,
    };
    setGraphData(modifiedData)
  }, [data]);

  return (
    <BarChart
    height={262}
    isXaxis={false}
    isHorizontal={true}
      data={graphData}
    />
  )
}