import { useEffect, useState } from "react";
import TreemapChart from "../charts/TreemapChart";
// import { color } from 'chart.js/helpers';

const keys = {
  'Menerapkan protokol kesehatan': {
    fields: ["adaptasi_akibat_covid19_melakukan_sesuai_protocol_covid19"],
    color: '#048737'
  },
  'Menyesuaikan produksi dan atau penyimpanan': {
    fields: ['adaptasi_akibat_covid19_mengurangi_produksi/stok',"adaptasi_akibat_covid19_menambah_produksi/stok","adaptasi_akibat_covid19_menyimpan_lebih_sedikit",'adaptasi_akibat_covid19_menyimpan_lebih_banyak'],
    color: '#5c724a'
  },
  'Menyesuaikan jumlah olahan dan atau harga jual': {
    fields: ["adaptasi_akibat_covid19_mengolah_lebih_sedikit", "adaptasi_akibat_covid19_mengolah_lebih_banyak", "adaptasi_akibat_covid19_menurunkan_harga","adaptasi_akibat_covid19_meningkatkan_harga"],
    color: '#a3b68a'
  },
  'Mencari pembeli dan atau rekan baru': {
    fields: ["adaptasi_akibat_covid19_mencari_pasar/pembeli", "adaptasi_akibat_covid19_mencari_rekan_baru"],
    color: '#c7b793'
  },
  'Menyesuaikan jumlah distribusi dan atau transportasi': {
    fields: ["adaptasi_akibat_covid19_mengurangi_distribusi/biayanya", "adaptasi_akibat_covid19_mengurangi_transportasi/biayanya", "adaptasi_akibat_covid19_menambah_transportasi/biayanya"],
    color: '#43682c'
  },
  'Mengguakan transaksi online dan atau membuat fasilitas baru': {
    fields: [
      "adaptasi_akibat_covid19_menyediakan_fasilitas_baru",
      "adaptasi_akibat_covid19_menggunakan_fasilitas_online"
    ],
    color: '#f5f5d5'
  },
  'Tidak ada': {
    fields: [
      'adaptasi_akibat_covid19_lainnya (tidak ada penyesuaian)'
    ],
    color: '#616847'
  },
  'Lainnya': {
    fields: [
      'adaptasi_akibat_covid19_mengurangi_jumlah/biaya_pekerja',
      'adaptasi_akibat_covid19_menambah_jumlah/biaya_pekerja',
      'adaptasi_akibat_covid19_mengurangi_pemasaran/biayanya',
      "adaptasi_akibat_covid19_menambah_pemasaran/biayanya",
      "adaptasi_akibat_covid19_menambah/mendapatkan_modal",
      "adaptasi_akibat_covid19_mengurangi_modal/menjual_aset"
    ],
    color: '#ffcd33'
  },
}

export default function AdaptasiCovid({ data }) {
  const [usaha, setUsaha] = useState([])
  
  useEffect(() => {
    const fields = Object.keys(keys)
    const rawData = []
    const mapData = {}
    data.map(item => {
      fields.map(field => {
        let value = 0
        keys[field].fields.map(col => {
          if (item[col] !== undefined) {
            value += item[col]
          }
          return col
        })
        if (mapData[field] !== undefined) {
          mapData[field].value += value
          mapData[field].percentage = (mapData[field].value/data.filter(item => item["adaptasi_akibat_covid19_melakukan_sesuai_protocol_covid19"] !== undefined).length * 100).toFixed(1) + '%'
        } else {
          mapData[field] = {
            name: 'field',
            value,
            color: keys[field].color,
            percentage: (value/data.filter(item => item["adaptasi_akibat_covid19_melakukan_sesuai_protocol_covid19"]!== undefined).length * 100).toFixed(1) + '%'
          }
        }
        return field
      })
      return item
    })
    Object.keys(mapData).map(key => {
      rawData.push(
        {
          name: key,
          value: mapData[key].value,
          percentage: mapData[key].percentage,
          color: mapData[key].color
        }
      )
      return key
    })
    setUsaha(rawData)
  }, [data])
  return (
    <>
      <TreemapChart
        data={{
          datasets: [
            {
              tree: usaha,
              key: 'value',
              labels: {
                display: true,
                formatter: (context) => {
                  const label = context.raw._data;
                  let labels = [];
                  if(label.name == 'Mengguakan transaksi online dan atau membuat fasilitas baru'){
                    labels.push('Mengguakan transaksi')
                    labels.push('online dan atau')
                    labels.push('membuat fasilitas baru')
                  }else{
                    labels.push(label.name)
                  }
                  labels.push(label.percentage)
                  return labels;
                }
              },
              backgroundColor(context) {
                if (context.type !== 'data') return 'transparent';
                return context.raw._data.color
              }
            },
          ],
        }}
      />
    </>
  )
}