import { useEffect, useState } from "react";
import PieChart from "../../charts/PieChart";
const labelFields = [
  'Pelajar',
  'Wirausaha',
  'Pekerja*',
  'Ibu/Bapak rumah tangga',
  'Sedang mencari kerja'
]
export default function AktivitasUtama({ data }) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 0.8)',
            'rgba(4,135,55, 0.6)',
            'rgba(4,135,55, 0.3)',
            'rgba(4,135,55, 0.2)',
            'rgba(4,135,55, 0.1)',
          ],
          borderColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    labelFields.map(field => {
      mapData[field] = 0
      return field
    })
    data.map(item => {
      // if (mapData[item.nama_kabupaten] !== undefined) {
      //   mapData[item.nama_kabupaten] += 1
      // }
      let containsLabel = null;

      for (let label of labelFields) {
        if (item["jenis_aktifitas_utama"].includes(label.replace('*',''))) {
          containsLabel = label;
          break;
        }else{
          containsLabel = null;
        }
      }
      if (containsLabel) {
        mapData[containsLabel] += 1
      }
      return item
    })
    rawData.labels = Object.keys(mapData)
    rawData.datasets[0].data = []
    rawData.labels.map(item => {
      rawData.datasets[0].data.push(((mapData[item]/data.length)*100).toFixed(1))
      return item
    })
    setGraphData(rawData)
  }, [data]);

  return (
    <div style={{ width: '350px', height: '350px', margin:"0px auto" }}>
    <PieChart
      data={graphData}
    />
    </div>
  )
}