import { useEffect, useState } from "react";
import DoughnutChart from "../charts/DoughnutChart";
const keys = {
  'Tidak terdampak': [4],
  'Kesulitan': [1,2,3],
  'Kemudahan': [5],
  'Kesulitan & kemudahan': [6],
}
export default function ProduksiPangan({ data }) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 0.8)',
            'rgba(4,135,55, 0.6)',
            'rgba(4,135,55, 0.3)',
            'rgba(4,135,55, 0.2)',
            'rgba(4,135,55, 0.1)',
          ],
          borderColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };

    const fields = Object.keys(keys)
    fields.map(field => {
      mapData[field] = 0
      return field
    })
    data.map(item => {
      // if (mapData[item.perubahan_pendapatan_keterangan] !== undefined) {
      //   mapData[item.perubahan_pendapatan_keterangan] += 1
      // } else {
      //   mapData[item.perubahan_pendapatan_keterangan] = 1
      // }
      fields.map(field => {
        console.log(keys[field])
        if (keys[field].includes(item.dampak_covid19)) {
          mapData[field] += 1
        }
        return field
      })
      return item
    })
    console.log(mapData)
    rawData.labels = Object.keys(mapData)
    rawData.datasets[0].data = []
    rawData.labels.map(item => {
      rawData.datasets[0].data.push(((mapData[item]/data.filter(item => Number.isInteger(item["dampak_covid19"])).length)*100).toFixed(1))
      return item
    })
    setGraphData(rawData)
  }, [data]);

  return  <div style={{ width: '350px', height: '350px', margin:"10px auto" }}><DoughnutChart data={graphData} /></div>
}