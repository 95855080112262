import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);


export const options = {
  maintainAspectRatio: true,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          return context.formattedValue + '%'
        }
      }
    },
    datalabels: {
      align: 'end', // Position the labels at the end of the bars
      anchor: 'end', // Anchor the labels at the end of the bars
      formatter: (value) => {
        return `${value}%`; // Format the labels as percentages
      },
      color: '#222',
      font: {
        size: 10, // Set the font size here
      }
    },
  },
  // barThickness: 8,
  // barPercentage: 0.4,
  scales: {
    x: {
      grid: {
        display: false
      },
      ticks: {
        display: true,
        stepSize: 50,
        font: {
          size: 14
        }
      }
    },
    y: {
      grid: {
        display: false
      },
      ticks: {
        font: {
          size: 14
        }
      }
    }
  },
  layout: {
    margin: 0,
    padding: {
      top: 30,
      right: 30,
    }
  }
};

export default function BubbleChart({ data, options, ...props }) {
  const theOptions = { ...options }
  theOptions.plugins = { ...options.plugins }
  if (props.title) {
    theOptions.plugins.title = {
      display: true,
      text: props.title
    }
  }
  return (
    <Bubble
      options={{ ...theOptions }}
      data={data}
      {...props}
    />
  )
}