import { useEffect, useState } from "react";
import BarChart from "../../charts/BarChart";
import BarStackChart from "../../charts/BarStackChart";

const labelFields = [
  'Buah',
  'Sayur',
  'Kacang',
  'Snack kemasan',
  'Daging olahan',
  'Mie instan',
  'Minuman kemasan',
  'Suplemen',
  'Gorengan',
]
export default function PerubahanKonsumsi2({ data }) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const mapData2 = {}
    const mapData3 = {}
    const mapData4 = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(4,135,55,1)',
          ],
          borderColor: [
            'rgba(4,135,55,1)',
          ],
          borderWidth: 1,
        },
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(92,114,74,1)',
          ],
          borderColor: [
            'rgba(92,114,74,1)',
          ],
          borderWidth: 1,
        },
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(163,182,138,1)',
          ],
          borderColor: [
            'rgba(163,182,138,1)',
          ],
          borderWidth: 1,
        },
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(199,183,147,1)',
          ],
          borderColor: [
            'rgba(199,183,147,1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    labelFields.map(field => {
      mapData[field] = 0
      mapData2[field] = 0
      mapData3[field] = 0
      mapData4[field] = 0
      return field
    })
    data.map(item => {
      if(item["frekuensi_buah"] === 1){
        mapData["Buah"] += 1
      }
if(item["frekuensi_sayur"] === 1){
        mapData["Sayur"] += 1
      }
if(item["frekuensi_kacang"] === 1){
        mapData["Kacang"] += 1
      }
if(item["frekuensi_kemasan_snack"] === 1){
        mapData["Snack kemasan"] += 1
      }
if(item["frekuensi_kemasan_daging"] === 1){
        mapData["Daging olahan"] += 1
      }
if(item["frekuensi_kemasan_mie"] === 1){
        mapData["Mie instan"] += 1
      }
if(item["frekuensi_kemasan_minuman"] === 1){
        mapData["Minuman kemasan"] += 1
      }
if(item["frekuensi_suplemen"] === 1){
        mapData["Suplemen"] += 1
      }
if(item["frekuensi_gorengan"] === 1){
        mapData["Gorengan"] += 1
      }

      if(item["frekuensi_buah"] === 2){
        mapData2["Buah"] += 1
      }
if(item["frekuensi_sayur"] === 2){
        mapData2["Sayur"] += 1
      }
if(item["frekuensi_kacang"] === 2){
        mapData2["Kacang"] += 1
      }
if(item["frekuensi_kemasan_snack"] === 2){
        mapData2["Snack kemasan"] += 1
      }
if(item["frekuensi_kemasan_daging"] === 2){
        mapData2["Daging olahan"] += 1
      }
if(item["frekuensi_kemasan_mie"] === 2){
        mapData2["Mie instan"] += 1
      }
if(item["frekuensi_kemasan_minuman"] === 2){
        mapData2["Minuman kemasan"] += 1
      }
if(item["frekuensi_suplemen"] === 2){
        mapData2["Suplemen"] += 1
      }
if(item["frekuensi_gorengan"] === 2){
        mapData2["Gorengan"] += 1
      }

      if(item["frekuensi_buah"] === 3){
        mapData3["Buah"] += 1
      }
if(item["frekuensi_sayur"] === 3){
        mapData3["Sayur"] += 1
      }
if(item["frekuensi_kacang"] === 3){
        mapData3["Kacang"] += 1
      }
if(item["frekuensi_kemasan_snack"] === 3){
        mapData3["Snack kemasan"] += 1
      }
if(item["frekuensi_kemasan_daging"] === 3){
        mapData3["Daging olahan"] += 1
      }
if(item["frekuensi_kemasan_mie"] === 3){
        mapData3["Mie instan"] += 1
      }
if(item["frekuensi_kemasan_minuman"] === 3){
        mapData3["Minuman kemasan"] += 1
      }
if(item["frekuensi_suplemen"] === 3){
        mapData3["Suplemen"] += 1
      }
if(item["frekuensi_gorengan"] === 3){
        mapData3["Gorengan"] += 1
      }

      if(item["frekuensi_buah"] === 4){
        mapData4["Buah"] += 1
      }
if(item["frekuensi_sayur"] === 4){
        mapData4["Sayur"] += 1
      }
if(item["frekuensi_kacang"] === 4){
        mapData4["Kacang"] += 1
      }
if(item["frekuensi_kemasan_snack"] === 4){
        mapData4["Snack kemasan"] += 1
      }
if(item["frekuensi_kemasan_daging"] === 4){
        mapData4["Daging olahan"] += 1
      }
if(item["frekuensi_kemasan_mie"] === 4){
        mapData4["Mie instan"] += 1
      }
if(item["frekuensi_kemasan_minuman"] === 4){
        mapData4["Minuman kemasan"] += 1
      }
if(item["frekuensi_suplemen"] === 4){
        mapData4["Suplemen"] += 1
      }
if(item["frekuensi_gorengan"] === 4){
        mapData4["Gorengan"] += 1
      }
      return item
    })
    rawData.labels = Object.keys(mapData)
    rawData.datasets[0].data = []
    rawData.datasets[1].data = []
    rawData.datasets[2].data = []
    rawData.datasets[3].data = []
    rawData.labels.map(item => {
      rawData.datasets[0].label = 'Sama'
      rawData.datasets[1].label = 'Berkurang'
      rawData.datasets[2].label = 'Bertambah'
      rawData.datasets[3].label = 'Tidak Pernah'
      rawData.datasets[0].data.push(((mapData[item]/data.length)*100).toFixed(1))
      rawData.datasets[1].data.push(((mapData2[item]/data.length)*100).toFixed(1))
      rawData.datasets[2].data.push(((mapData3[item]/data.length)*100).toFixed(1))
      rawData.datasets[3].data.push(((mapData4[item]/data.length)*100).toFixed(1))
      return item
    })
    setGraphData(rawData)
  }, [data]);

  return (
    <BarStackChart
      height={130}
      isHorizontal={true}
      data={graphData}
    />
  )
}