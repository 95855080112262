import { useEffect, useState } from "react";
import BarChart from "../../charts/BarChart";

const labelFields = {
'LSM': 'pemilik_lahan_LSM',
'Keluarga': 'pemilik_lahan_anggota_keluarga_lain',
'Gereja': 'pemilik_lahan_gereja',
'Orang Lain/Rekan Kerja': 'pemilik_lahan_orang_lain/rekan_kerja',
'Pemerintah': 'pemilik_lahan_pemerintah',
// 'Produksi': 'pemilik_lahan_produksi',
'Sendiri dan istri/keluarga': 'pemilik_lahan_saya_dan_istri/anggota_keluarga_lain',
'Pribadi dan Rekan Kerja': 'pemilik_lahan_saya_dan_rekan_kerja',
'Pribadi': 'pemilik_lahan_saya_sendiri',
'Suami/Istri': 'pemilik_lahan_suami/istri_saya',
'Tidak relevan': 'pemilik_lahan_tidak_tahu/tidak_relevan',
'Wilayah adat/marga lain': 'pemilik_lahan_wilayah_adat_marga_lain',
'Wilaya adat/marga sendiri': 'pemilik_lahan_wilayah_adat_marga_sendiri',
'Lainnya': 'pemilik_lahan_lainnya ',
}

export default function PemilikLahan({ data }) {
  const [graphData, setGraphData] = useState({
    labels: Object.keys(labelFields),
    datasets: []
  })

  useEffect(() => {
    const rawData = {
      labels: Object.keys(labelFields),
      datasets: [
        {
          label: '',
          data: [],
          barThickness: 8,
          barPercentage: 0.4,
          backgroundColor: [
            '#048737',
            '#048737',
            '#048737',
            '#048737',
            '#048737',
            '#048737',
          ],
        },
      ]
    }
    const fields = Object.values(labelFields)
    const fieldsObj = {}
    fields.map(field => {
      fieldsObj[field] = 0
      return field
    })
    data.map(item => {
      fields.map(field => {
        if (item[field] !== undefined) {
          fieldsObj[field] += item[field]
        }
        return field
      })
      return item
    })
    fields.map(field => {
      rawData.datasets[0].data.push(((fieldsObj[field]/data.length) * 100).toFixed(1))
      return field
    })

    const sortedData = {
      ...rawData,
      labels: [...rawData.labels].sort((a, b) => rawData.datasets[0].data[rawData.labels.indexOf(a)] - rawData.datasets[0].data[rawData.labels.indexOf(b)]),
      datasets: rawData.datasets.map(dataset => ({
        ...dataset,
        data: [...dataset.data].sort((a, b) => a - b), // Sort data in descending order
      })),
    };
    setGraphData(sortedData)
  }, [data]);

  return (
    <div style={{  height: '400px', margin:"10px auto" }}>
    <BarChart
      isHorizontal={true}
      height={300}
      isXaxis={false} 
      data={graphData}
    /></div>
  )
}