import { useEffect, useState } from "react";
import TreemapChart from "../charts/TreemapChart";
// import { color } from 'chart.js/helpers';

const keys = {
  'Menerapkan protokol kesehatan': {
    fields: ['strategi_operasional_protocol'],
    color: '#048737'
  },
  'Menyesuaikan produksi dan atau penyimpanan': {
    fields: ['strategi_mengurangi', 'strategi_menambah', 'strategi_menyimpan_sedikit', 'strategi_menyimpan_banyak'],
    color: '#5c724a'
  },
  'Menyesuaikan pengolahan dan atau harga': {
    fields: ['strategi_mengolah_sedikit', 'strategi_mengolah_banyak', 'strategi_menurunkan_harga', 'strategi_meningkatkan_harga'],
    color: '#a3b68a'
  },
  'Mencari pembeli dan atau rekan baru': {
    fields: ['strategi_mencari_pembeli', 'strategi_rekan_baru'],
    color: '#c7b793'
  },
  'Menyesuaikan jumlah pekerja dan atau pemasaran': {
    fields: ['strategi_mengurangi_pekerja', 'strategi_menambah_pekerja', 'strategi_mengurangi_pemasaran', 'strategi_menambah_pemasaran'],
    color: '#f5f5d5'
  },
  'Membuat fasilitas baru dan atau transaksi online': {
    fields: [
      'strategi_fasilitas_baru',
      'strategi_transaksi_online'
    ],
    color: '#f1975a'
  },
  'Tidak ada': {
    fields: [
      'strategi_tidakada'
    ],
    color: '#616847'
  },
  'Lainnya**': {
    fields: [
      'strategi_lainnya',
      'strategi_mengurangi_modal',
      'strategi_menambah modal_modal',
      'strategi_menambah_transportasi',
      'strategi_mengurangi_transportasi',
      'strategi_menambah_distribusi',
      'strategi_mengurangi_distribusi'
    ],
    color: '#ffcd33'
  },
}

export default function StrategiResponden({ data, unfilteredData }) {
  const [usaha, setUsaha] = useState([])
  
  useEffect(() => {
    const fields = Object.keys(keys)
    const rawData = []
    const mapData = {}
    data.map(item => {
      fields.map(field => {
        let value = 0
        keys[field].fields.map(col => {
          if (item[col] !== undefined) {
            value += item[col]
          }
          return col
        })
        if (mapData[field] !== undefined) {
          mapData[field].value += value
          mapData[field].percentage = (mapData[field].value/data.filter(item => Number.isInteger(item["strategi_operasional_protocol"])).length * 100).toFixed(1) + '%'
        } else {
          mapData[field] = {
            name: 'field',
            value,
            color: keys[field].color,
            percentage: (value/data.filter(item => Number.isInteger(item["strategi_operasional_protocol"])).length * 100).toFixed(1) + '%'
          }
        }
        return field
      })
      return item
    })
    console.log("Lainnya**",data.filter(item => Number.isInteger(item["strategi_operasional_protocol"])).length)
    Object.keys(mapData).map(key => {
      rawData.push(
        {
          name: key,
          value: mapData[key].value,
          percentage: mapData[key].percentage,
          color: mapData[key].color
        }
      )
      return key
    })
    setUsaha(rawData)
  }, [data])
  return (
    <>
      <TreemapChart
        data={{
          datasets: [
            {
              tree: usaha,
              key: 'value',
              labels: {
                display: true,
                formatter: (context) => {
                  const label = context.raw._data;
                  let labels = [];
                  if(label.name == 'Menyesuaikan jumlah pekerja dan atau pemasaran'){
                    labels.push('Menyesuaikan jumlah pekerja')
                    labels.push('dan atau pemasaran')
                  }else if(label.name == 'Membuat fasilitas baru dan atau transaksi online'){
                    labels.push('Membuat fasilitas baru')
                    labels.push('dan atau transaksi online')
                  }else{
                    labels.push(label.name)
                  }
                  labels.push(label.percentage)
                  return labels;
                }
              },
              backgroundColor(context) {
                if (context.type !== 'data') return 'transparent';
                return context.raw._data.color
              }
            },
          ],
        }}
      />
    </>
  )
}