import { useEffect, useState } from "react"
import BarChart from "../charts/BarChart";
import DoughnutChart from "../charts/DoughnutChart";
import Card from "../misc/Card";

const labelBantuan = {
  'Tidak mendapat bantuan': 0,
  'Mendapat bantuan': 0,
}

const labelSumberBantuan = {
  'Pemerintah': 'bantuan_pemerintah',
  'LSM': 'bantuan_lsm',
  'Swasta': 'bantuan_swasta',
  'Lembaga keagamaan': 'bantuan_lembaga_keagamaan',
  'Koperasi': 'bantuan_koperasi',
  'Perbankan': 'bantuan_perbankan',
  'Lainnya': 'bantuan_lainnya',
}

const labelBentukBantuan = {
  'Uang': 'bantuan_uang',
  'Pelatihan': 'bantuan_pelatihan',
  'Fasilitasi': 'bantuan_fasilitasi',
  'Pemasaran': 'bantuan_pemasaran',
  'Lainnya': 'bantuan_lainnya2',
}

export default function BantuanUsaha({data, unfilteredData}) {
  const [bantuan, setBantuan] = useState({})
  const [bantuanPercentage, setBantuanPercentage] = useState()

  useEffect(() => {
    const fLabelBantuan = { ...labelBantuan }
    data.map(item => {
      if (fLabelBantuan[item.bantuan] !== undefined) {
        fLabelBantuan[item.bantuan] += 1
      }
      return item
    })
    const rawPercentageBantuan = []
    Object.keys(fLabelBantuan).map(item => {
      rawPercentageBantuan.push(((fLabelBantuan[item]/data.length)*100).toFixed(1))
      return item
    })
    setBantuan(fLabelBantuan)
    setBantuanPercentage(rawPercentageBantuan)
  }, [data]);

  /**
   * sumber bantuan
   */
  const [sumberBantuan, setSumberBantuan] = useState({
    labels: [],
    datasets: []
  })
  useEffect(() => {
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [],
          backgroundColor: [
            'rgba(4,135,55, 1)',
            'rgba(199,183,147, 1)',
            'rgba(245,245,213, 1)',
            'rgba(147,199,183, 1)',
            'rgba(4,135,22, 0.2)',
            'rgba(163,182,138, 1)',
            'rgba(147,199,183, 1)',
          ],
          borderWidth: 0,
        },
      ],
    };
    const fields = Object.values(labelSumberBantuan)
    const fieldsObj = {}
    let pembagi = 0
    fields.map(field => {
      fieldsObj[field] = 0
      return field
    })
    data.map(item => {
      if (item[fields[0]] !== undefined) {
        pembagi += 1
      }
      fields.map(field => {
        if (item[field] !== undefined) {
          fieldsObj[field] += item[field]
        }
        return field
      })
      return item
    })
    rawData.labels = Object.keys(labelSumberBantuan)
    rawData.datasets[0].data = []
    Object.values(labelSumberBantuan).map(item => {
      rawData.datasets[0].data.push(((fieldsObj[item]/data.filter(item => Number.isInteger(item["bantuan_pemerintah"])).length)*100).toFixed(1))
      return item
    })
    setSumberBantuan(rawData)
  }, [data]);

  /**
   * bentuk bantuan
   */
  const [bentukBantuan, setBentukBantuan] = useState({})
  useEffect(() => {
    const fields = Object.values(labelBentukBantuan)
    const fieldsObj = {}
    let pembagi = 0
    fields.map(field => {
      fieldsObj[field] = 0
      return field
    })
    data.map(item => {
      if (item[fields[0]] !== undefined) {
        pembagi += 1
      }
      fields.map(field => {
        if (item[field] !== undefined) {
          fieldsObj[field] += item[field]
        }
        return field
      })
      return item
    })
    const rawData = []
    fields.map(field => {
      rawData.push(((fieldsObj[field]/data.filter(item => Number.isInteger(item["bantuan_uang"])).length) * 100).toFixed(1))
      return field
    })
    setBentukBantuan(rawData)
  }, [data]);

  return (
    <>
      <div className="block md:flex">
        <Card classes="xl:w-[30%]">
          {bantuanPercentage &&
          <div>
            <h4 className="text-black font-bold leading-[25px]">Yang Mendapat Bantuan</h4>
            <div><span className="text-[#048737] font-extrabold text-[100px]">{bantuanPercentage[1]}</span><span className="font-bold">%</span></div>
            <div><span className={'p-1 text-[12px] rounded-[2px] text-white ' + ((parseFloat(bantuanPercentage[0]) < 50) ? 'bg-[#F36242]' : 'bg-[#6DDE6B]')}>{(parseFloat(bantuanPercentage[0])).toFixed(1)}%</span> <span className="text-[#A0A0A0] text-[12px]">tidak mendapat bantuan</span></div>
          </div>}
          <div className='prose mt-4' style={{fontSize: 12, width: '100%', maxWidth: '100%'}}>
          Jumlah responden yang mendapatkan bantuan sebanyak 96 orang yang terdiri dari 31 laki-laki dan 65 perempuan dimana 75 responden merupakan OAP dan 21 responden non OAP
              </div>
        </Card>
        <Card classes="xl:w-[30%]">
        <div style={{ width: '350px', height: '350px', margin:"0px auto" }}>
          <h4 className="text-black font-bold leading-[25px]">Pemberi Bantuan</h4>
          <BarChart
            data={sumberBantuan}
            isXaxis={false}
            height={200}
            isHorizontal={true}
          />
          </div>
        </Card>
        <Card classes="xl:w-[40%]">
        <div style={{ width: '350px', height: '350px', margin:"0px auto" }}>
          <h4 className="text-black font-bold leading-[25px]">Bentuk Bantuan</h4>
          <BarChart
            isHorizontal={true}
            height={200}
            isXaxis={false}
            data={{
              labels: Object.keys(labelBentukBantuan),
              datasets: [
                {
                  label: '',
                  data: bentukBantuan,
                  backgroundColor: [
                    'rgba(4,135,55, 1)',
                    'rgba(199,183,147, 1)',
                    'rgba(245,245,213, 1)',
                    'rgba(147,199,183, 1)',
                    'rgba(4,235,122, 1)',
                    'rgba(163,182,138, 1)',
                  ],
                },
              ]
            }}
          />
          </div>
        </Card>
      </div>
    </>
  )
}
