import { useEffect, useState } from "react";
import BarChart from "../../charts/BarChart";

const labelFields = [
  'Buruk',
  'Baik',
  'Belum, tapi khawatir',
  'Tidak berdampak',
]
export default function FrequensiMakanan({ data }) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const mapData2 = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(4,135,55,1)',
          ],
          borderColor: [
            'rgba(4,135,55,1)',
          ],
          borderWidth: 1,
        },
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(163,182,138,1)',
          ],
          borderColor: [
            'rgba(163,182,138,1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    labelFields.map(field => {
      mapData[field] = 0
      mapData2[field] = 0
      return field
    })
    data.map(item => {
      if(item["dampak_jumlah_frekuensi"] === 1){
        if (mapData["Buruk"] !== undefined) {
          mapData["Buruk"] += 1
        }else{
          mapData["Buruk"] = 1;
        }
      }else if(item["dampak_jumlah_frekuensi"] === 2){
        if (mapData["Baik"] !== undefined) {
          mapData["Baik"] += 1
        }else{
          mapData["Baik"] = 1;
        }
      }else if(item["dampak_jumlah_frekuensi"] === 3){
        if (mapData["Belum, tapi khawatir"] !== undefined) {
          mapData["Belum, tapi khawatir"] += 1
        }else{
          mapData["Belum, tapi khawatir"] = 1;
        }
      }else if(item["dampak_jumlah_frekuensi"] === 4){
        if (mapData["Tidak berdampak"] !== undefined) {
          mapData["Tidak berdampak"] += 1
        }else{
          mapData["Tidak berdampak"] = 1;
        }
      }
      if(item["dampak_jenis"] === 1){
        if (mapData2["Buruk"] !== undefined) {
          mapData2["Buruk"] += 1
        }else{
          mapData2["Buruk"] = 1;
        }
      }else if(item["dampak_jenis"] === 2){
        if (mapData2["Baik"] !== undefined) {
          mapData2["Baik"] += 1
        }else{
          mapData2["Baik"] = 1;
        }
      }else if(item["dampak_jenis"] === 3){
        if (mapData2["Belum, tapi khawatir"] !== undefined) {
          mapData2["Belum, tapi khawatir"] += 1
        }else{
          mapData2["Belum, tapi khawatir"] = 1;
        }
      }else if(item["dampak_jenis"] === 4){
        if (mapData2["Tidak berdampak"] !== undefined) {
          mapData2["Tidak berdampak"] += 1
        }else{
          mapData2["Tidak berdampak"] = 1;
        }
      }
      return item
    })
    rawData.labels = Object.keys(mapData)
    rawData.datasets[0].label = "Jumlah dan frekuensi"
    rawData.datasets[1].label = "Jenis**"
    rawData.datasets[0].data = []
    rawData.datasets[1].data = []
    rawData.labels.map(item => {
      rawData.datasets[0].data.push(((mapData[item]/data.filter(item => Number.isInteger(item["dampak_jumlah_frekuensi"])).length)*100).toFixed(1))
      rawData.datasets[1].data.push(((mapData2[item]/data.filter(item => Number.isInteger(item["dampak_jenis"])).length)*100).toFixed(1))
      return item
    })
    setGraphData(rawData)
  }, [data]);

  return (
    <BarChart
    isXaxis={false}
      height={130}
      data={graphData}
      legend={true}
    />
  )
}