import { useEffect, useState } from "react";
import BarChart from "../../charts/BarChart";
const labelFields = [
  'Kayu bakar',
  'Minyak tanah/ parafin',
  'LPG',
  'Arang',
  'Kotoran hewan',
  'Lainnya'
]
export default function BahanBakarBar({ data }) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 0.8)',
            'rgba(4,135,55, 0.6)',
            'rgba(4,135,55, 0.3)',
            'rgba(4,135,55, 0.2)',
            'rgba(4,135,55, 0.1)',
          ],
          borderColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    labelFields.map(field => {
      mapData[field] = 0
      return field
    })
    data.map(item => {
      if(item["fuel_kayu"] === 1){
        mapData["Kayu bakar"] += 1
      }
      if(item["fuel_minyak_tanah"] === 1){
        mapData["Minyak tanah/ parafin"] += 1
      }
      if(item["fuel_lpg"] === 1){
        mapData["LPG"] += 1
      }
      if(item["fuel_arang"] === 1){
        mapData["Arang"] += 1
      }
      if(item["fuel_kotoran"] === 1){
        mapData["Kotoran hewan"] += 1
      }
      if(item["fuel_lainnya"] === 1){
        mapData["Lainnya"] += 1
      }
      return item
    })
    rawData.labels = Object.keys(mapData)
    rawData.datasets[0].data = []
    rawData.labels.map(item => {
      rawData.datasets[0].data.push(((mapData[item]/data.length)*100).toFixed(1))
      return item
    })
    setGraphData(rawData)
  }, [data]);

  return (
    <div style={{ width: '400px', height: '400px', margin:"10px auto" }}>
    <BarChart
    height={300}
    isXaxis={false}
    isHorizontal={true}
      data={graphData}
    /></div>
  )
}