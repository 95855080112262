export default function IconBreadcrumb() {
  return (
    <svg
      fill="#000000"
      viewBox="0 0 492.308 492.308"
      style={{ height: '20px', fill: '#fff' }}>
      <g>
        <g>
          <rect x="9.846" y="472.615" width="472.615" height="19.692"/>
        </g>
      </g>
      <g>
        <g>
          <rect x="9.846" y="354.462" width="351.183" height="19.692"/>
        </g>
      </g>
      <g>
        <g>
          <rect x="9.846" y="236.308" width="472.615" height="19.692"/>
        </g>
      </g>
      <g>
        <g>
          <rect x="9.846" y="118.154" width="236.308" height="19.692"/>
        </g>
      </g>
      <g>
        <g>
          <rect x="9.846" width="472.615" height="19.692"/>
        </g>
      </g>
    </svg>
  )
}