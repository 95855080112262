import { useEffect, useState } from "react";
import TreemapChart from "../../charts/TreemapChart";
// import { color } from 'chart.js/helpers';

const keys = {
  'Jarak menjauh': {
    fields: ["kondisi_jarak_menjauh"],
    color: '#048737'
  },
  'Wilayah dibatasi': {
    fields: ["kondisi_wilayah_dibatasi"],
    color: '#5c724a'
  },
  'Jam buka berubah': {
    fields: ["kondisi_jambuka_berubah"],
    color: '#a3b68a'
  },
  'Sedikit tempat': {
    fields: ['kondisi_sedikit_tempat'],
    color: '#c7b793'
  },
  'Mobilitas berubah': {
    fields: ['kondisi_mobilitas_berubah'],
    color: '#f5f5d5'
  },
  'Jam malam': {
    fields: [
      'kondisi_jammalam'
    ],
    color: '#f1975a'
  },
  'Transportasi terbatas': {
    fields: [
      'kondisi_transportasi_terbatas'
    ],
    color: '#616847'
  },
  'Transportasi mahal': {
    fields: [
      'kondisi_transportasi_mahal'
    ],
    color: '#616847'
  },
  'Kurang terjangkau': {
    fields: [
      'kondisi_makanan_kurangterjangkau'
    ],
    color: '#616847'
  },
  'Kurang tersedia': {
    fields: [
      'kondisi_makanan_kurangtersedia'
    ],
    color: '#616847'
  },
  'Kurang selera': {
    fields: [
      'kondisi_makanan_kurangselera'
    ],
    color: '#616847'
  },
  'Kurang nyaman': {
    fields: [
      'kondisi_kurang_nyaman'
    ],
    color: '#616847'
  },
  'Tidak ada perubahan kondisi': {
    fields: [
      'kondisi_tidakada'
    ],
    color: '#ffcd33'
  },
}

export default function KondisiMembeliMakanan({ data }) {
  const [usaha, setUsaha] = useState([])
  
  useEffect(() => {
    const fields = Object.keys(keys)
    const rawData = []
    const mapData = {}
    data.map(item => {
      fields.map(field => {
        let value = 0
        keys[field].fields.map(col => {
          if (item[col] !== undefined) {
            value += item[col]
          }
          return col
        })
        if (mapData[field] !== undefined) {
          mapData[field].value += value
          mapData[field].percentage = (mapData[field].value/data.length * 100).toFixed(1) + '%'
        } else {
          mapData[field] = {
            name: 'field',
            value,
            color: keys[field].color,
            percentage: (value/data.length * 100).toFixed(1) + '%'
          }
        }
        return field
      })
      return item
    })
    Object.keys(mapData).map(key => {
      rawData.push(
        {
          name: key,
          value: mapData[key].value,
          percentage: mapData[key].percentage,
          color: mapData[key].color
        }
      )
      return key
    })
    setUsaha(rawData)
  }, [data])
  return (
    <>
      <TreemapChart
        data={{
          datasets: [
            {
              tree: usaha,
              key: 'value',
              labels: {
                display: true,
                formatter: (context) => context.raw._data.name + ',' + context.raw._data.percentage,
              },
              backgroundColor(context) {
                if (context.type !== 'data') return 'transparent';
                return context.raw._data.color
              }
            },
          ],
        }}
      />
    </>
  )
}