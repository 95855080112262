import { useEffect, useState } from "react";
import BubbleChart from "../../charts/BubbleChart";

const keys = {
  'Konsumsi stok makanan': ["adaptasi_stok"],
  'Berkebun/ bertani/ beternak': ["adaptasi_berkebun"],
  'Konsumsi produk sendiri': ["adaptasi_konsumsi_produksendiri"],
  'Berbagi': ["adaptasi_berbagi"],
  'Kurangi jatah makan': ["adaptasi_mengurangi_jatahmakan_sendiri","adaptasi_mengurangijatah_keluarga"],
  'Atur jumlah simpanan makanan': ["adaptasi_menyimpansedikit","adaptasi_menyimpanbanyak"],
  'Atur jumlah olahan makanan': ["adaptasi_mengolahsedikit","adaptasi_mengolahbanyak"],
  'Tidak ada': ["adaptasi_tidakada"],
}
export default function AdaptasiKeluarga2({ data }) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 0.8)',
            'rgba(4,135,55, 0.6)',
            'rgba(4,135,55, 0.3)',
            'rgba(4,135,55, 0.2)',
            'rgba(4,135,55, 0.1)',
          ],
          borderColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    const keys = {
      'Konsumsi stok makanan': ((data.filter(item => Number.isInteger(item["adaptasi_stok"]) && item["adaptasi_stok"] == 1).length/data.filter(item => Number.isInteger(item["adaptasi_berkebun"])).length) * 100),
      'Berkebun/ bertani/ beternak': ((data.filter(item => Number.isInteger(item["adaptasi_berkebun"]) && item["adaptasi_berkebun"] == 1).length/data.filter(item => Number.isInteger(item["adaptasi_berkebun"])).length) * 100),
      'Konsumsi produk sendiri': ((data.filter(item => Number.isInteger(item["adaptasi_konsumsi_produksendiri"]) && item["adaptasi_konsumsi_produksendiri"] == 1).length/data.filter(item => Number.isInteger(item["adaptasi_berkebun"])).length) * 100),
      'Berbagi': ((data.filter(item => Number.isInteger(item["adaptasi_berbagi"]) && item["adaptasi_berbagi"] == 1).length/data.filter(item => Number.isInteger(item["adaptasi_berbagi"])).length) * 100),
      'Kurangi jatah makan': ((data.filter(item => Number.isInteger(item["adaptasi_mengurangi_jatahmakan_sendiri"]) && item["adaptasi_mengurangi_jatahmakan_sendiri"] == 1 ||  Number.isInteger(item["adaptasi_mengurangijatah_keluarga"]) && item["adaptasi_mengurangijatah_keluarga"] == 1).length/data.filter(item => Number.isInteger(item["adaptasi_berkebun"])).length) * 100),
      'Atur jumlah simpanan makanan':  ((data.filter(item => Number.isInteger(item["adaptasi_menyimpansedikit"]) && item["adaptasi_menyimpansedikit"] == 1).length/data.filter(item => Number.isInteger(item["adaptasi_menyimpansedikit"])).length) * 100) + ((data.filter(item => Number.isInteger(item["adaptasi_menyimpanbanyak"]) && item["adaptasi_menyimpanbanyak"] == 1).length/data.filter(item => Number.isInteger(item["adaptasi_menyimpanbanyak"])).length) * 100),
      'Atur jumlah olahan makanan': ((data.filter(item => Number.isInteger(item["adaptasi_mengolahsedikit"]) && item["adaptasi_mengolahsedikit"] == 1 ).length/data.filter(item => Number.isInteger(item["adaptasi_mengolahsedikit"])).length) * 100) + ((data.filter(item =>  Number.isInteger(item["adaptasi_mengolahbanyak"]) && item["adaptasi_mengolahbanyak"] == 1).length/data.filter(item => Number.isInteger(item["adaptasi_mengolahbanyak"])).length) * 100),
      'Tidak ada': ((data.filter(item => Number.isInteger(item["adaptasi_tidakada"]) && item["adaptasi_tidakada"] == 1).length/data.filter(item => Number.isInteger(item["adaptasi_berkebun"])).length) * 100),
    }
    rawData.labels = Object.keys(keys)
    rawData.datasets[0].data = []
    rawData.datasets[0].label = "hallo"
    Object.keys(keys).map(key => {
      rawData.datasets[0].data.push( {
        x: key,
        y: (keys[key]),
        r: (keys[key]) // radius of the bubble
      })
      return key
    })
    setGraphData(rawData)
  }, [data]);

  return (
    <BubbleChart
      height={100}
      data={graphData}
      options={{
        maintainAspectRatio: true,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                console.log("VAL",context)
                return context.parsed.y.toFixed(1) + '%'
              }
            }
          },
          datalabels: {
            align: 'end', // Position the labels at the end of the bars
            anchor: 'end', // Anchor the labels at the end of the bars
            formatter: (value) => {
              return `${value.r.toFixed(1)}%`; // Format the labels as percentages
            },
            color: '#222',
            font: {
              size: 10, // Set the font size here
            }
          },
        },
        // barThickness: 8,
        // barPercentage: 0.4,
        scales: {
          x: {
            title: {
              display: true,
            },
            labels: Object.keys(keys),
            type: 'category',
          }, // Set the y-axis min and max
          y: {
            ticks: {
              display: false,
              font: {
                size: 13
              }
            }
          }
        },
        layout: {
          margin: 0,
          padding: {
            top: 30,
            right: 30,
          }
        }
      }}
      category={Object.keys(keys)}
    />
  )
}