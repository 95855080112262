import { useEffect, useState } from "react"
import BarChart from "../../charts/BarChart";

const labelBentukBantuan = {
  'Produksi pangan': 'sumber_penghasilan_produksi_pangan',
  'Bantuan': 'sumber_penghasilan_bantuan_pemerintah/gereja/lembaga lain',
  'Pekerjaan sampingan': 'sumber_penghasilan_pekerjaan_sampingan',
  'Pemberian keluarga': 'sumber_penghasilan_pemberian_anggota_keluarga_lain',
  'Lainnya': 'sumber_penghasilan_lainnya',
}

export default function SumberPendapatan({data}) {
  const [bentukBantuan, setBentukBantuan] = useState({})
  useEffect(() => {
    const fields = Object.values(labelBentukBantuan)
    const fieldsObj = {}
    fields.map(field => {
      fieldsObj[field] = 0
      return field
    })
    data.map(item => {
      fields.map(field => {
        if (item[field] !== undefined) {
          fieldsObj[field] += item[field]
        }
        return field
      })
      return item
    })
    const rawData = []
    console.log(fieldsObj)
    fields.map(field => {
      rawData.push(((fieldsObj[field]/data.length) * 100).toFixed(1))
      return field
    })
    setBentukBantuan(rawData)
  }, [data]);

  return (
    <>
      <BarChart
        height={200}
        isXaxis={false}
        data={{
          labels: Object.keys(labelBentukBantuan),
          datasets: [
            {
              label: '',
              data: bentukBantuan,
              backgroundColor: [
                '#048737',
                '#048737',
                '#048737',
                '#048737',
                '#048737',
                '#048737',
              ],
            },
          ]
        }}
      />
    </>
  )
}
