import { useEffect, useState } from "react";
import PolarChart from "../charts/PolarChart";

const labelPendidikanTerakhir = {
  'Tidak sekolah': 0,
  'SD': 0,
  'SMP': 0,
  'SMA': 0,
  'Perguruan tinggi': 0,
}

export default function PendidikanTerakhir({ data }) {
  const [pendidikanTerakhir, setPendidikanTerakhir] = useState(labelPendidikanTerakhir)
  const [pendidikanTerakhirPercentage, setPendidikanTerakhirPercentage] = useState(labelPendidikanTerakhir)

  useEffect(() => {
    /**
     * pendidikan terakhir
     */
    const fLabelPendidikanTerakhir = { ...labelPendidikanTerakhir }
    data.map(item => {
      if (fLabelPendidikanTerakhir[item.pendidikan_terakhir] !== undefined) {
        fLabelPendidikanTerakhir[item.pendidikan_terakhir] += 1
      }
      return item
    })
    const rawPercentagePendidikanTerakhir = []
    Object.keys(fLabelPendidikanTerakhir).map(item => {
      rawPercentagePendidikanTerakhir.push(((fLabelPendidikanTerakhir[item]/data.length)*100).toFixed(1))
      return item
    })
    setPendidikanTerakhirPercentage(rawPercentagePendidikanTerakhir)
    setPendidikanTerakhir(fLabelPendidikanTerakhir)
  }, [data]);

  return (
    <PolarChart
    isXaxis={false}
      data={{
        labels: Object.keys(pendidikanTerakhir),
        datasets: [
          {
            label: '',
            data: pendidikanTerakhirPercentage,
            backgroundColor: [
            'rgba(4,135,55, 1)',
            'rgba(199,183,147, 1)',
            'rgba(245,245,213, 1)',
            'rgba(147,199,183, 1)',
            'rgba(163,182,138, 1)',
            ],
          },
        ]
      }}
    />
  )
}