import { useEffect, useState } from "react";
import DoughnutChart from "../charts/DoughnutChart";

const labelFields = [
  'Tidak sekolah',
  'SD',
  'SMP',
  'SMA',
  'Perguruan tinggi/ Universitas'
]
export default function PerubahanPendapatanCovid({ data }) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 0.8)',
            'rgba(4,135,55, 0.6)',
            'rgba(4,135,55, 0.3)',
            'rgba(4,135,55, 0.2)',
            'rgba(4,135,55, 0.1)',
          ],
          borderColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    data.map(item => {
      if (mapData[item.perubahan_pendapatan_keterangan] !== undefined) {
        mapData[item.perubahan_pendapatan_keterangan] += 1
      } else {
        mapData[item.perubahan_pendapatan_keterangan] = 1
      }
      return item
    })
    rawData.labels = Object.keys(mapData)
    rawData.datasets[0].data = []
    rawData.labels.map(item => {
      rawData.datasets[0].data.push(((mapData[item]/data.length)*100).toFixed(1))
      return item
    })
    setGraphData(rawData)
  }, [data]);

  return <DoughnutChart data={graphData} legend={true} 
  height={200}/>
}